import React from 'react';

import { Container } from './styles';
import { useSiedeBar } from '../../../../hooks/sidebar';

const Content: React.FC = ({ children }) => {
  const { isOpened } = useSiedeBar();

  return <Container isMenuOpened={isOpened}>{children}</Container>;
};

export default Content;
