import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px 30px;
  background-color: var(--chat-mesage-body);

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border: 4px solid rgba(0, 0, 0, 0.2);
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--link-active-sidebar);
    border: 4px solid var(--link-active-sidebar);
  }
`;
