import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const ContainerIntro = styled.div`
  display: flex;
  flex: 1;
  align-items: stretch;
  background: var(--header);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 6px solid var(--chat-line-bottom);

  > img {
    width: 250px;
    height: auto;
  }

  > h1 {
    margin-top: 28px;
    font-size: 32px;
    font-weight: normal;
  }

  > h2 {
    margin-top: 28px;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
  }
`;
