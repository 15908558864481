import React, { useState, useEffect, useCallback, useMemo } from 'react';
// eslint-disable-next-line import/no-duplicates
import { parseISO, formatRelative } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ptBR from 'date-fns/locale/pt-BR';

import { useChat } from '../../../hooks/chat';
import { useSocket } from '../../../hooks/socket';

import alert from '../../../assets/fail.mp3';

import ChatItem from '../LeftSide/ChatItem';
import api from '../../../services/api';

import * as S from './styles';

interface SessionMessage {
  id: string;
  phone: string;
  contact_name: string;
  last_message: string;
  updated_at: string;
}

interface Paginate {
  data: SessionMessage[];
  page: number;
  per_page: number;
  last_page: number;
  total: number;
}

const NewChat: React.FC = () => {
  const [messageSocket, setMessageSocket] = useState<SessionMessage>();

  const {
    showNewChat,
    updateShowNewChat,
    updateSessionMessage,
    newMessages,
    updateNewMessage,
  } = useChat();

  const { socket } = useSocket();

  const handleSearch = useCallback(async () => {
    const response = await api.get<Paginate>('/message/session/department', {
      params: { page: 1, per_page: 20 },
    });

    const { data } = response;

    updateNewMessage(data);
  }, [updateNewMessage]);

  const takeSession = useCallback(
    async (session_id) => {
      const response = await api.put<Paginate>(
        `/message/session/take/${session_id}`,
      );

      if (response.data) {
        const sessionMessage = await api.get<Paginate>(
          '/message/session/user',
          {
            params: {
              page: 1,
              per_page: 20,
            },
          },
        );

        updateSessionMessage(sessionMessage.data);
      }

      updateShowNewChat();
    },
    [updateSessionMessage, updateShowNewChat],
  );

  const audio = useMemo(() => {
    return new Audio(alert);
  }, []);

  const listenSocket = useCallback((): void => {
    socket?.on('DEL_DEPARTMENT', (chatMessages: SessionMessage) => {
      setMessageSocket(chatMessages);
    });

    socket?.on('ADD_DEPARTMENT', (chatMessages: SessionMessage) => {
      setMessageSocket(chatMessages);

      audio.play();
    });
  }, [audio, socket]);

  useEffect(() => {
    return () => {
      socket?.off('DEL_DEPARTMENT');
      socket?.off('ADD_DEPARTMENT');
    };
  }, [socket]);

  const renderMessage = useCallback((): string => {
    let messageText = 'Nenhuma mesagem na fila';

    if (newMessages?.data.length === 0) {
      messageText = 'Nenhuma mensagem na fila';
    } else if (newMessages?.data.length === 1) {
      messageText = '1 mensagem na fila';
    } else {
      messageText = `${newMessages?.data.length.toString()} mensagens na fila`;
    }

    return messageText;
  }, [newMessages]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  useEffect(() => {
    listenSocket();
  }, [listenSocket]);

  useEffect(() => {
    if (messageSocket) {
      handleSearch();
    }
  }, [handleSearch, messageSocket]);

  return (
    <S.Container show={showNewChat}>
      <S.Header>
        <S.IconBack onClick={updateShowNewChat} />
        <div>{renderMessage()}</div>
      </S.Header>
      <S.ChatList>
        {newMessages?.data.map((msg) => (
          <S.SessionButton key={msg.id} onClick={() => takeSession(msg.id)}>
            <ChatItem
              key={msg.id}
              name={msg.contact_name}
              time={formatRelative(parseISO(msg.updated_at), new Date(), {
                locale: ptBR,
              })}
              message={msg.last_message}
              isFocused={false}
            />
          </S.SessionButton>
        ))}
      </S.ChatList>
    </S.Container>
  );
};

export default NewChat;
