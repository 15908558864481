import React, { useCallback, useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaTags } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';
import {
  HeaderBackground,
  HeaderInfo,
  Input,
  Button,
  Label,
  FormGroup,
} from '../../../components';

import { useToast } from '../../../hooks/toast';

import api from '../../../services/api';

import * as S from './styles';

interface FormData {
  phone: string;
  country_code: string;
  dial_code: string;
  name: string;
  company_name: string;
  erp_code: string;
}

const schema = Yup.object().shape({
  phone: Yup.string().required('Número Whatsapp obrigatório'),
  country_code: Yup.string().required('Codigo Paìs obrigatório'),
  dial_code: Yup.string().required('Número Whatsapp sem Paìs obrigatório'),
  name: Yup.string().required('Nome Whatsapp obrigatório'),
});

interface Contact {
  id: string;
  phone: string;
  country_code: string;
  dial_code: string;
  name: string;
  company_name: string;
  erp_code: string;
}

interface RouterParams {
  id?: string | undefined;
}

const ContactForm: React.FC = () => {
  const { id } = useParams<RouterParams>();

  const { register, handleSubmit, errors, reset } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState<Contact>();
  const history = useHistory();

  const onSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        if (contact?.id) {
          const response = await api.put<Contact>(
            `/contacts/${contact?.id}`,
            data,
          );

          setContact(response.data);
        } else {
          await api.post('/contacts', data);
          reset();

          history.push('/contacts');
        }

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Salvo com sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },
    [contact, addToast],
  );

  useEffect(() => {
    if (id) {
      api.get<Contact>(`/contacts/${id}`).then((response) => {
        const { data } = response;
        reset(data);
        setContact(data);
      });
    }
  }, [id, reset]);

  return (
    <S.Container>
      <HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/contacts">
              <MdKeyboardBackspace size={24} color="#fff" />
              Contatos
            </Link>
          </S.BackLink>
          <HeaderInfo
            title={contact?.id ? `Editando: ${contact?.phone}` : 'Novo Contato'}
            icon={FaTags}
          />
        </S.HeaderPageDatail>
      </HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>Código Paìs</Label>
            <Input
              type="text"
              register={register}
              errors={errors.country_code}
              name="country_code"
              placeholder="Ex: 55"
            />
          </FormGroup>

          <FormGroup>
            <Label>Numéro Whatsapp</Label>
            <Input
              type="text"
              register={register}
              errors={errors.dial_code}
              name="dial_code"
              placeholder="ex: 8588888888"
            />
          </FormGroup>

          <FormGroup>
            <Label>Número Whatsapp completo</Label>
            <Input
              type="text"
              register={register}
              errors={errors.phone}
              name="phone"
              placeholder="ex: 558588888888"
            />
          </FormGroup>

          <FormGroup>
            <Label>Nome Whatsapp</Label>
            <Input
              type="text"
              register={register}
              errors={errors.name}
              name="name"
              placeholder="Nome usuário whatsapp"
            />
          </FormGroup>

          <FormGroup>
            <Label>Código Empresa</Label>
            <Input
              type="text"
              register={register}
              errors={errors.erp_code}
              name="erp_code"
              placeholder="Código usado erp"
            />
          </FormGroup>

          <FormGroup>
            <Label>Nome Empresa</Label>
            <Input
              type="text"
              register={register}
              errors={errors.company_name}
              name="company_name"
              placeholder="Nome Empresa"
            />
          </FormGroup>

          <Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};

export default ContactForm;
