import React from 'react';
import { parseISO, format } from 'date-fns';

import * as S from './styles';

interface LocationViewerProps {
  lat: string;
  lon: string;
  type: 'Inbound' | 'Outbound';
  messageDate: string;
}

const LocationViewer: React.FC<LocationViewerProps> = ({
  lat,
  lon,
  type,
  messageDate,
}) => {
  const link = `https://www.google.com.br/maps/place/${lat},${lon}`;
  return (
    <S.Container messageType={type}>
      <S.FileContainer messageType={type}>
        <S.Header>
          <a target="_blank" href={link} rel="noreferrer">
            <S.IconFile />
            <div>Localização</div>
          </a>
        </S.Header>
        <S.Footer>
          <S.MessageDate>
            {format(parseISO(messageDate), 'dd/MM/yyyy HH:mm')}
          </S.MessageDate>
        </S.Footer>
      </S.FileContainer>
    </S.Container>
  );
};

export default LocationViewer;
