import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaTags } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';
import {
  HeaderBackground,
  HeaderInfo,
  Input,
  Button,
  Label,
  FormGroup,
  Checkbox,
} from '../../../components';

import { useToast } from '../../../hooks/toast';

import api from '../../../services/api';

import { groupBy } from '../../../utils/groupBy';

import * as S from './styles';

interface FormData {
  name: string;
}

const schema = Yup.object().shape({
  name: Yup.string().required('Permissão obrigatória'),
});

interface Permission {
  id: string;
  name: string;
  reports: [{ id: string; name: string }];
}

interface PermissionReport {
  id: string;
  name: string;
  category: string;
}

interface RouterParams {
  id?: string | undefined;
}

const PermissionForm: React.FC = () => {
  const { register, handleSubmit, errors, reset } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [permission, setPermission] = useState<Permission>();
  const [permissionReport, setPermissionReport] = useState([]);
  const { id } = useParams<RouterParams>();

  const onSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        if (id) {
          const response = await api.put<Permission>(
            `/permissions/${id}`,
            data,
          );

          setPermission(response.data);
        } else {
          await api.post('/permissions', data);
          reset();
        }
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Salvo com sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, id, reset],
  );

  useEffect(() => {
    api.get<PermissionReport>('/reports/permissions').then((response) => {
      const { data } = response;

      const perm: any = data; // group by aceita tipo any

      const newPermissionReport = groupBy(perm, 'category');

      setPermissionReport(newPermissionReport);
    });
  }, []);

  useEffect(() => {
    if (id) {
      api.get<Permission>(`/permissions/${id}`).then((response) => {
        const { data } = response;

        setPermission(data);

        const permissionFormEdit = {
          ...data,
          reports: data.reports.map((report) => report.id),
        };

        reset(permissionFormEdit);
      });
    }
  }, [id, reset]);

  return (
    <S.Container>
      <HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <MdKeyboardBackspace size={24} color="#fff" />
            <Link to="/permissions">Permissões</Link>
          </S.BackLink>
          <HeaderInfo
            title={id ? `Editando: ${permission?.name}` : 'Nova Permissão'}
            icon={FaTags}
          />
        </S.HeaderPageDatail>
      </HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>Permissão</Label>
            <Input
              type="text"
              register={register}
              errors={errors.name}
              name="name"
              placeholder="Permissão"
            />
          </FormGroup>

          {Object.entries(permissionReport).map((item) => (
            <div key={item[0]}>
              <S.TitlePermission>{item[0]}</S.TitlePermission>
              <Checkbox
                register={register}
                name="reports"
                items={Array.from(item[1])}
              />
            </div>
          ))}

          <Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};

export default PermissionForm;
