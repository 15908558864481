import React, { useEffect, useCallback, useRef } from 'react';

import {
  AudioPlayer,
  VideoPlayer,
  FileDownload,
  ImageViewer,
  LocationViewer,
} from '../../../../../components';

import api from '../../../../../services/api';

import { useChat } from '../../../../../hooks/chat';

import MessageItem from '../MessageItem';

import * as S from './styles';

interface Messages {
  id: string;
  session_id: string;
  type: 'Inbound' | 'Outbound';
  message_type: 'text' | 'image' | 'audio' | 'video' | 'file' | 'location';
  message?: string;
  status: string;
  message_date: string;
  file_caption?: string;
  file_url?: string;
  file_url_expiry?: string;
  location_longitude?: string;
  location_latitude?: string;
}

const ChatBody: React.FC = () => {
  const {
    messages,
    updateMessages,
    currentSession,
    updateCurrentSession,
  } = useChat();

  const messagesBody = useRef<HTMLInputElement>(null);

  const loadMessages = useCallback(async () => {
    if (currentSession?.id) {
      const response = await api.get<Messages[]>(
        `/messages/${currentSession?.id}`,
      );

      updateMessages(response.data);
    } else {
      updateMessages([] as Messages[]);
    }
  }, [currentSession, updateMessages]);

  useEffect(() => {
    return () => {
      updateCurrentSession(undefined);
    };
  }, [updateCurrentSession]);

  useEffect(() => {
    loadMessages();
  }, [loadMessages]);

  useEffect(() => {
    if (
      messagesBody.current &&
      messagesBody.current?.scrollHeight > messagesBody.current?.offsetHeight
    ) {
      messagesBody.current.scrollTop =
        messagesBody.current.scrollHeight - messagesBody.current.offsetHeight;
    }
  }, [messages]);

  return (
    <S.Container ref={messagesBody}>
      {messages?.map((msg) => {
        if (msg.message_type === 'image' && msg.file_url) {
          return (
            <ImageViewer
              key={msg.id}
              file={msg.file_url}
              type={msg.type}
              messageDate={msg.message_date}
            />
          );
        }

        if (msg.message_type === 'audio') {
          return (
            <AudioPlayer
              key={msg.id}
              file={msg.file_url}
              type={msg.type}
              messageDate={msg.message_date}
            />
          );
        }

        if (msg.message_type === 'video') {
          return (
            <VideoPlayer
              key={msg.id}
              file={msg.file_url}
              type={msg.type}
              messageDate={msg.message_date}
            />
          );
        }

        if (msg.message_type === 'file' && msg.file_url) {
          return (
            <FileDownload
              key={msg.id}
              file={msg.file_url}
              type={msg.type}
              fileName={msg.file_caption}
              messageDate={msg.message_date}
            />
          );
        }

        if (
          msg.message_type === 'location' &&
          msg.location_latitude &&
          msg.location_longitude
        ) {
          return (
            <LocationViewer
              key={msg.id}
              lat={msg.location_latitude}
              lon={msg.location_longitude}
              type={msg.type}
              messageDate={msg.message_date}
            />
          );
        }

        return (
          <MessageItem
            key={msg.id}
            messageType={msg.type}
            messageDate={msg.message_date}
            message={msg.message || ''}
          />
        );
      })}
    </S.Container>
  );
};

export default ChatBody;
