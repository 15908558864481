import React from 'react';

import ChatHeader from './ChatHeader';
import ChatBody from './ChatBody';

import * as S from './styles';

const RightSide: React.FC = () => {
  return (
    <S.Container>
      <ChatHeader />
      <ChatBody />
    </S.Container>
  );
};

export default RightSide;
