import styled, { keyframes, css } from 'styled-components';
import Tooltip from '../Tooltip';

const loading = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  /* width: 100%; */
  background: var(--background-input);
  border-radius: 4px;
  padding: 1.2rem;
  border: 1px solid var(--border-input);
  color: #999;
  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) =>
    props.isFocused &&
    css`
      color: var(--selected-input);
      border-color: var(--selected-input);
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: var(--selected-input);
    `}

  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: var(--text);
    &::placeholder {
      color: var(--placeholder);
    }
  }

  > svg {
    margin-right: 16px;
  }
`;

export const ListItens = styled.ul`
  position: absolute;
  width: fit-content;
  background: var(--background-input);
  z-index: 2;
`;
export const Item = styled.li`
  /* width: 100%; */
  background: var(--background-input);
  padding: 1.2rem;
  color: #999;
  display: flex;
  align-items: center;
  cursor: pointer;

  border-top: 0.5px solid var(--border-input);
  border-left: 0.5px solid var(--border-input);
  border-right: 0.5px solid var(--border-input);

  &:hover {
    text-decoration: none;
    background-color: var(--backgrount-item-menu-hover);
  }
  &:last-child {
    margin-bottom: 8px;
    border-bottom: 0.5px solid var(--border-input);
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  svg {
    margin: 0;
  }
  span {
    background: var(--error);
    color: var(--background-text-error);
    font-weight: 500;

    &::before {
      border-color: var(--error) transparent;
    }
  }
`;

export const Spinner = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid;

  border-color: var(--border-loading-active) var(--border-loading)
    var(--border-loading);

  border-radius: 50%;
  animation: ${loading} 0.7s linear infinite;
`;

export const ItemSelectedList = styled.div`
  display: flex;
  align-items: center;
`;

export const ItemSelectedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ItemSelected = styled.div`
  flex: 1 1 auto;
  background-color: var(--background-selected);
  color: var(--text);
  padding: 2px 8px 2px 8px;
  margin-right: 2px;
`;

export const IconRemoveItemSelected = styled.div`
  flex: 1 1 auto;
  height: 22px;
  margin: 2px 8px 0px 0px;
`;
