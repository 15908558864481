/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useCallback } from 'react';
import { parseISO, format } from 'date-fns';

import ImageViewer from 'react-simple-image-viewer';

import * as S from './styles';

interface ImageViewProps {
  file: string;
  type: 'Inbound' | 'Outbound';
  messageDate: string;
}

const ImageView: React.FC<ImageViewProps> = ({ file, type, messageDate }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [file];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = (): void => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <S.Container messageType={type}>
      <S.FileContainer messageType={type}>
        <S.Header>
          {images.map((src, index) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <img
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              src={src}
              onClick={() => openImageViewer(index)}
              alt=""
            />
          ))}

          {isViewerOpen && (
            <ImageViewer
              src={images}
              currentIndex={currentImage}
              onClose={closeImageViewer}
              backgroundStyle={{
                backgroundColor: 'rgba(0,0,0,0.9)',
              }}
            />
          )}
        </S.Header>
        <S.Footer>
          <S.MessageDate>
            {format(parseISO(messageDate), 'dd/MM/yyyy HH:mm')}
          </S.MessageDate>
        </S.Footer>
      </S.FileContainer>
    </S.Container>
  );
};

export default ImageView;
