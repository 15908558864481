import React, { useCallback, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useSiedeBar } from '../../hooks/sidebar';
import { useAuth } from '../../hooks/auth';
import { useTheme } from '../../hooks/theme';
import { useSocket } from '../../hooks/socket';
import { useToast } from '../../hooks/toast';

import { dynamicSort } from '../../utils/sort';

import brandImg from '../../assets/brand.png';
import avartarImgDefault from '../../assets/avatar.png';

import * as S from './styles';

interface IUsersOnline {
  // [key: string]: {
  tenant_id: string;
  user_id: string;
  user_name: string;
  department_id: string;
  department_name: string;
  // }
}

const Header: React.FC = () => {
  const { signOut, user, updateUsersOnline } = useAuth();
  const { onToogleOpenSideBar } = useSiedeBar();
  const { theme, onChangeTheme } = useTheme();
  const history = useHistory();
  const { connectSocket, disconnectSocket, socket } = useSocket();
  const { addToast } = useToast();

  const avatarImg = user.avatar_url ? user.avatar_url : avartarImgDefault;

  useEffect(() => {
    connectSocket();
  }, [connectSocket]);

  useEffect(() => {
    return () => {
      disconnectSocket();
    };
  }, [disconnectSocket]);

  const listenSocket = useCallback((): void => {
    socket?.on('USERS_ONLINE', (users_online: IUsersOnline) => {
      // Object.keys = cria um array com a chave (user_id)
      // Object.values = Cria array com os valores (tenant_id, user_id, user_name ....)

      updateUsersOnline(
        Object.values(users_online)
          .map((item) => {
            return { ...item };
          })
          .sort(dynamicSort('user_name')), // -user_name > para ordem decrescente
      );
    });

    socket?.on('SIGNOUT', () => {
      signOut();

      addToast({
        type: 'error',
        title: 'Novo Login',
        description: 'Essa conta acabou de fazer login em outro dispositivo',
      });
    });
  }, [addToast, signOut, socket, updateUsersOnline]);

  useEffect(() => {
    if (socket) {
      listenSocket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listenSocket]);

  const handleToogleMenu = useCallback(() => {
    onToogleOpenSideBar();
  }, [onToogleOpenSideBar]);

  const handleSetTheme = useCallback(
    (themeSelected: string) => {
      onChangeTheme(themeSelected);
    },
    [onChangeTheme],
  );

  const navegateToProfile = useCallback(() => {
    history.push('/profile');
    // eslint-disable-next-line
  }, []);

  return (
    <S.Container>
      <S.WrapperLeft>
        <S.IconMenu onClick={handleToogleMenu} size={24} />
        <S.Brand width="24" height="24" src={brandImg} />
        <S.Title>Smart Message</S.Title>
      </S.WrapperLeft>

      <S.WrapperRight>
        {theme === 'dark' && (
          <S.IconDarkTheme size={24} onClick={() => handleSetTheme('light')} />
        )}
        {theme === 'light' && (
          <S.IconLightTheme size={24} onClick={() => handleSetTheme('dark')} />
        )}
        <S.IconNotifications size={24} />

        {/* <IconContainer>
          <MdSettings size={24} />
        </IconContainer> */}
        <S.AvatarInfo>
          <S.Avatar src={avatarImg} role="button" onClick={navegateToProfile} />
          <S.UserInfo>
            <S.UserName>{user.name}</S.UserName>
            {/* <RoleName>{user.users_roles[0]}</RoleName> */}
          </S.UserInfo>
        </S.AvatarInfo>
        <S.IconSettings onClick={signOut} size={24} />
      </S.WrapperRight>
    </S.Container>
  );
};

export default Header;
