import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './Home';
import SessionMessageResume from './SessionMessageResume';

const Contact: React.FC = () => {
  return (
    <Switch>
      <Route path="/dashboard" component={Home} isPrivate exact />
      <Route
        path="/dashboard/session/resume"
        component={SessionMessageResume}
        isPrivate
      />
    </Switch>
  );
};

export default Contact;
