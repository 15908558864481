import React, { useMemo } from 'react';
// eslint-disable-next-line import/no-duplicates
import { parseISO, formatRelative } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ptBR from 'date-fns/locale/pt-BR';

import { useChat } from '../../../../hooks/chat';

import ChatItem from './ChatItem';

import * as S from './styles';

interface SessionMessage {
  id: string;
  phone: string;
  contact_name: string;
  last_message: string;
  current?: {
    name: string;
  };
  closed?: {
    name: string;
  };
  department?: {
    name: string;
  };
  transfer?: {
    name: string;
  };
  rating?: string;
  updated_at: string;
}

interface LeftSideProps {
  sessionMessages: SessionMessage[];
  sessionType: string;
}

const LeftSide: React.FC<LeftSideProps> = ({
  sessionMessages,
  sessionType,
}) => {
  const { updateCurrentSession, currentSession } = useChat();

  const renderLeft = useMemo<React.ReactElement>(() => {
    switch (sessionType) {
      case 'started':
        return <div>Iniciadas</div>;
        break;
      case 'progress':
        return <div>Em atendimento</div>;
        break;
      case 'finished':
        return <div>Finalizadas</div>;
        break;
      case 'expired':
        return <div>Expiradas</div>;
        break;
      default:
        return <div>Em espera</div>;
    }
  }, [sessionType]);

  return (
    <S.Container>
      <S.Header>{renderLeft}</S.Header>

      <S.ChatList>
        {sessionMessages.length > 0 &&
          sessionMessages?.map((msg) => (
            <S.SessionButton
              key={msg.id}
              onClick={() => updateCurrentSession(msg)}
            >
              <ChatItem
                key={msg.id}
                name={msg.contact_name}
                time={formatRelative(parseISO(msg.updated_at), new Date(), {
                  locale: ptBR,
                })}
                message={msg.last_message}
                isFocused={currentSession?.id === msg.id}
                closed={msg?.closed?.name}
                current={msg?.current?.name}
                department={msg?.department?.name}
                rating={msg?.rating}
              />
            </S.SessionButton>
          ))}
      </S.ChatList>
    </S.Container>
  );
};

export default LeftSide;
