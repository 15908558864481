import React, { useCallback, useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaTags } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';
import {
  HeaderBackground,
  HeaderInfo,
  Input,
  Button,
  Label,
  FormGroup,
} from '../../../components';

import { useToast } from '../../../hooks/toast';

import api from '../../../services/api';

import * as S from './styles';

interface FormData {
  menu_number: string;
  name: string;
}

const schema = Yup.object().shape({
  menu_number: Yup.string().required('Código de menu obrigatório'),
  name: Yup.string().required('Nome Departamento obrigatório'),
});

interface Department {
  id: string;
  menu_number: string;
  name: string;
}

interface RouterParams {
  id?: string | undefined;
}

const DepartmentForm: React.FC = () => {
  const { id } = useParams<RouterParams>();

  const { register, handleSubmit, errors, reset } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [department, setDepartment] = useState<Department>();
  const history = useHistory();

  const onSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        if (department?.id) {
          const response = await api.put<Department>(
            `/departments/${department?.id}`,
            data,
          );

          setDepartment(response.data);
        } else {
          await api.post('/departments', data);
          reset();

          history.push('/departments');
        }

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Salvo com sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },
    [department?.id, addToast, reset, history],
  );

  useEffect(() => {
    if (id) {
      api.get<Department>(`/departments/${id}`).then((response) => {
        const { data } = response;
        reset(data);
        setDepartment(data);
      });
    }
  }, [id, reset]);

  return (
    <S.Container>
      <HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/departments">
              <MdKeyboardBackspace size={24} color="#fff" />
              Derpatamentos
            </Link>
          </S.BackLink>
          <HeaderInfo
            title={
              department?.id
                ? `Editando: ${department?.name}`
                : 'Novo Departamento'
            }
            icon={FaTags}
          />
        </S.HeaderPageDatail>
      </HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>Código Menu</Label>
            <Input
              type="text"
              register={register}
              errors={errors.menu_number}
              name="menu_number"
              placeholder="Informe um número exclusivo para o menu!"
            />
          </FormGroup>

          <FormGroup>
            <Label>Departamento</Label>
            <Input
              type="text"
              register={register}
              errors={errors.name}
              name="name"
              placeholder="Informe o nome do departamento"
            />
          </FormGroup>

          <Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};

export default DepartmentForm;
