import React, { useState, useCallback, useRef, ChangeEvent } from 'react';
import EmojiPicker from 'emoji-picker-react';
import { IoMdDocument, IoMdImage, IoMdVideocam } from 'react-icons/io';

import api from '../../../../services/api';

import { useChat } from '../../../../hooks/chat';
import { useToast } from '../../../../hooks/toast';

import * as S from './styles';

interface SessionMessage {
  id: string;
  phone: string;
  contact_name: string;
  last_message: string;
  updated_at: string;
}

interface Paginate {
  data: SessionMessage[];
  page: number;
  per_page: number;
  last_page: number;
  total: number;
}

const ChatFooter: React.FC = () => {
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);
  const [emojiOpen, setEmojiOpen] = useState(false);
  const [attachOpen, setAttachOpen] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const inputFileVideoRef = useRef<HTMLInputElement>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const inputFileImageRef = useRef<HTMLInputElement>(null);

  const {
    updateMessages,
    messages,
    updateSessionMessage,
    currentSession,
  } = useChat();
  const { addToast } = useToast();

  const handleSubmit = useCallback(async () => {
    if (message !== '') {
      setSending(true);

      const data = {
        type: 'text',
        message,
        session_id: currentSession?.id,
      };

      setTimeout(() => {
        setSending(false);
        setMessage('');
        setEmojiOpen(false);
      }, 300);

      try {
        const messageResponse = await api.post('/message/outbound', data);

        updateMessages([...messages, { ...messageResponse.data }]);

        const sessionResponse = await api.get<Paginate>(
          '/message/session/user',
          {
            params: { page: 1, perPage: 20 },
          },
        );

        updateSessionMessage(sessionResponse.data);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao enviar a mensagem',
        });
      }
    }
  }, [
    addToast,
    currentSession?.id,
    message,
    messages,
    updateMessages,
    updateSessionMessage,
  ]);

  const handleInputKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        if (!sending) {
          handleSubmit();
        }
      }
    },
    [handleSubmit, sending],
  );

  const handleEmojiClick = useCallback(
    (e, emojiObject) => {
      setMessage(message + emojiObject.emoji);
      inputRef.current?.focus();
    },
    [message],
  );

  const handleAttachClick = useCallback(() => {
    if (emojiOpen) {
      setEmojiOpen(false);
    }
    setAttachOpen(!attachOpen);
  }, [attachOpen, emojiOpen]);

  const handleClickIconFileVideo = useCallback(() => {
    inputFileVideoRef.current?.click();
  }, []);

  const handleClickIconFile = useCallback(() => {
    inputFileRef.current?.click();
  }, []);

  const handleClickIconFileImage = useCallback(() => {
    inputFileImageRef.current?.click();
  }, []);

  const handleInputFileVideoChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const data = new FormData();

        data.append('video', e.target.files[0]);
        data.append('session_id', currentSession?.id || '');

        api.post('/message/outbound/video', data).then(() => {
          setAttachOpen(false);
        });
      }
    },
    [currentSession?.id],
  );

  const handleInputFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const data = new FormData();

        data.append('file', e.target.files[0]);
        data.append('session_id', currentSession?.id || '');

        api.post('/message/outbound/file', data).then(() => {
          setAttachOpen(false);
        });
      }
    },
    [currentSession?.id],
  );

  const handleInputImageChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const data = new FormData();

        data.append('image', e.target.files[0]);
        data.append('session_id', currentSession?.id || '');

        api.post('/message/outbound/image', data).then(() => {
          setAttachOpen(false);
        });
      }
    },
    [currentSession?.id],
  );

  return (
    <S.Container>
      <S.EmojiContainer emojiopen={emojiOpen.toString()}>
        <EmojiPicker
          disableSearchBar
          disableSkinTonePicker
          onEmojiClick={handleEmojiClick}
          groupNames={{
            smileys_people: 'Pessoas',
            animals_nature: 'Animais e Natureza',
            food_drink: 'Comida e Bebida',
            travel_places: 'Atividades',
            activities: 'Viagens e Locais',
            objects: 'Objetos',
            symbols: 'Simbolos',
            flags: 'Bandeiras',
            recently_used: 'Usados recentemente',
          }}
        />
      </S.EmojiContainer>

      <S.MessageContainer>
        <S.LeftContainer>
          <S.IconClose
            emojiopen={emojiOpen.toString()}
            onClick={() => setEmojiOpen(false)}
          />

          <S.IconEmoji
            onClick={() => {
              if (attachOpen) {
                setAttachOpen(false);
              }

              setEmojiOpen(true);
            }}
            emojiopen={emojiOpen.toString()}
          />

          <S.IconContainer>
            <S.IconAttach
              onClick={handleAttachClick}
              attachopen={attachOpen.toString()}
            />

            <input
              type="file"
              id="fileVideo"
              ref={inputFileVideoRef}
              onChange={handleInputFileVideoChange}
            />

            <S.IconFileVideo attachopen={attachOpen.toString()}>
              <IoMdVideocam onClick={handleClickIconFileVideo} />

              <span>Vídeo</span>
            </S.IconFileVideo>

            <input
              type="file"
              id="file"
              ref={inputFileRef}
              onChange={handleInputFileChange}
            />

            <S.IconFile attachopen={attachOpen.toString()}>
              <IoMdDocument onClick={handleClickIconFile} />

              <span>Documentos</span>
            </S.IconFile>

            <input
              type="file"
              id="fileImage"
              accept="image/x-png,image/gif,image/jpeg"
              ref={inputFileImageRef}
              onChange={handleInputImageChange}
            />
            <S.IconFileImage attachopen={attachOpen.toString()}>
              <IoMdImage onClick={handleClickIconFileImage} />

              <span>Fotos</span>
            </S.IconFileImage>
          </S.IconContainer>
        </S.LeftContainer>
        <S.TextArea>
          <input
            type="text"
            placeholder="Digite uma mensagem"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyUp={handleInputKeyUp}
            ref={inputRef}
          />
        </S.TextArea>
        <S.RightContainer>
          {!sending && <S.IconSend onClick={() => handleSubmit()} />}
          {sending && <S.IconConfirm />}
        </S.RightContainer>
      </S.MessageContainer>
    </S.Container>
  );
};

export default ChatFooter;
