import styled, { css, keyframes } from 'styled-components';

import { MdSend, MdThumbUp, MdClose } from 'react-icons/md';
import { GrEmoji } from 'react-icons/gr';
import { FiPaperclip } from 'react-icons/fi';

interface EmojiProps {
  emojiopen: string;
}

interface AttachProps {
  attachopen: string;
}

export const Container = styled.div``;

export const EmojiContainer = styled.div<EmojiProps>`
  height: ${(props) => (props.emojiopen === 'true' ? '280px' : '0')};
  overflow-y: hidden;
  transition: all ease 0.3s;

  aside.emoji-picker-react {
    width: auto;
    background: var(--chat-emoji-area);
  }

  .emoji-picker-react .emoji-group:before {
    background: var(--chat-emoji-area);
    /* background: none; */
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  height: 62px;
  align-items: center;
  background: var(--primary);
`;

export const LeftContainer = styled.div`
  display: flex;
  margin: 0 15px;
`;

export const TextArea = styled.div`
  flex: 1;

  > input {
    width: 100%;
    height: 40px;
    border: 0;
    outline: 0;
    background-color: #fff;
    border-radius: 20px;
    font-size: 1.5rem;
    color: #4a4a4a;
    padding-left: 15px;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  margin: 0 15px;
`;

const IconCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 4px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  cursor: pointer;
  color: var(--text-header);

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

export const IconSend = styled(MdSend)`
  ${IconCss}
`;

export const IconConfirm = styled(MdThumbUp)`
  ${IconCss}
`;

export const IconEmoji = styled(GrEmoji)<EmojiProps>`
  ${IconCss}
  color: ${(props) =>
    props.emojiopen === 'true'
      ? 'var(--button-secondary)'
      : 'var(--text-header)'};
`;

export const IconClose = styled(MdClose)<EmojiProps>`
  ${IconCss}
  overflow: hidden;
  width: ${(props) => (props.emojiopen === 'true' ? '40px' : '0')};
  height: ${(props) => (props.emojiopen === 'true' ? '40px' : '0')};
  transition: all ease 0.3s;
`;

export const IconContainer = styled.div`
  position: relative;
  input {
    display: none;
  }
`;

export const IconAttach = styled(FiPaperclip)<AttachProps>`
  ${IconCss}
  ${(props) =>
    props.attachopen === 'true' &&
    css`
      background: rgba(255, 255, 255, 0.2);
    `};
`;

const animateIconFileVideo = keyframes`
  0% {
   top: 0px;
   opacity: 0;
   display: none;
  }
  100% {
    top: -190px;
    opacity: 1;
    display: block;
  }
`;

const animateIconFile = keyframes`
  0% {
   top: 0px;
   opacity: 0;
   display: none;
  }
  100% {
    top: -130px;
    opacity: 1;
    display: block;
  }
`;

const animateIconImage = keyframes`
  0% {
   top: 0px;
   opacity: 0;
   display: none;
  }
  100% {
    top: -70px;
    opacity: 1;
    display: block;
  }
`;

export const IconFileVideo = styled.div<AttachProps>`
  position: absolute;
  display: ${(props) => (props.attachopen === 'true' ? 'flex' : 'none')};
  align-items: center;

  svg {
    background: linear-gradient(
      to bottom,
      var(--chat-header-1) 0%,
      var(--chat-header-2) 100%
    );

    padding: 12px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    color: var(--text-header);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px;
  }

  span {
    margin-left: 8px;
    font-size: 14px;
    background-color: var(--button-secondary);
    color: #fff;
    padding: 4px 8px;
    border-radius: 16px;
    border: 1px solid var(--button-secondary-border);
    opacity: 0;
  }

  &:hover {
    span {
      opacity: 1;
    }
  }

  ${(props) =>
    props.attachopen === 'true' &&
    css`
      animation: ${animateIconFileVideo} 0.3s;
      top: -190px;
    `}
`;

export const IconFile = styled.div<AttachProps>`
  position: absolute;
  display: ${(props) => (props.attachopen === 'true' ? 'flex' : 'none')};
  align-items: center;

  svg {
    background: linear-gradient(
      to bottom,
      var(--chat-header-1) 0%,
      var(--chat-header-2) 100%
    );

    padding: 12px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    color: var(--text-header);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px;
  }

  span {
    margin-left: 8px;
    font-size: 14px;
    background-color: var(--button-secondary);
    color: #fff;
    padding: 4px 8px;
    border-radius: 16px;
    border: 1px solid var(--button-secondary-border);
    opacity: 0;
  }

  &:hover {
    span {
      opacity: 1;
    }
  }

  ${(props) =>
    props.attachopen === 'true' &&
    css`
      animation: ${animateIconFile} 0.3s;
      top: -130px;
    `}
`;

export const IconFileImage = styled.div<AttachProps>`
  position: absolute;
  display: ${(props) => (props.attachopen === 'true' ? 'flex' : 'none')};
  align-items: center;

  svg {
    background: linear-gradient(
      to bottom,
      var(--chat-header-1) 0%,
      var(--chat-header-2) 100%
    );

    padding: 12px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    color: var(--text-header);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px;
  }

  span {
    margin-left: 8px;
    font-size: 14px;
    background-color: var(--button-secondary);
    color: #fff;
    padding: 4px 8px;
    border-radius: 16px;
    border: 1px solid var(--button-secondary-border);
    opacity: 0;
  }

  &:hover {
    span {
      opacity: 1;
    }
  }

  ${(props) =>
    props.attachopen === 'true' &&
    css`
      animation: ${animateIconImage} 0.3s;
      top: -70px;
    `};
`;
