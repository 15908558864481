import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  /* width: 100%; */
  background: var(--background-input);
  border-radius: 4px;
  padding: 1.2rem;
  border: 2px solid var(--border-input);
  color: #999;
  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) =>
    props.isFocused &&
    css`
      color: var(--selected-input);
      border-color: var(--selected-input);
    `}

  textarea {
    flex: 1;
    background: transparent;
    border: 0;
    color: var(--text);
    &::placeholder {
      color: var(--placeholder);
    }
    &:disabled,
    &:read-only {
      cursor: not-allowed;
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  svg {
    margin: 0;
  }
  span {
    background: var(--error);
    color: var(--background-text-error);
    font-weight: 500;

    &::before {
      border-color: var(--error) transparent;
    }
  }
`;
