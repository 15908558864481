import React, {
  InputHTMLAttributes,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { FieldError, Controller, Control } from 'react-hook-form';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { MdCancel } from 'react-icons/md';
import { debounce } from 'lodash';
import api from '../../services/api';
import * as S from './styles';

type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined;

interface Option {
  id: number | string;
  name: string;
}

interface AutoCompleteProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: object;
  icon?: React.ComponentType<IconBaseProps>;
  register: RefReturn;
  errors: FieldError | undefined;
  control: Control;
  url: string;
  delay?: number;
  defaultOption?: Option;
}

const AutoComplete: React.FC<AutoCompleteProps> = ({
  name,
  register,
  errors,
  control,
  containerStyle = {},
  icon: Icon,
  url,
  delay = 500,
  defaultOption,
}) => {
  const inputRefVisible = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([] as Option[]);
  const [optionSelected, setOptionSelected] = useState<Option | undefined>(
    defaultOption as Option,
  );

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const delaySearch = useRef(
    debounce((value: string) => {
      setLoading(true);
      api
        .get(url, {
          params: {
            page: 1,
            per_page: 10,
            term: value,
          },
        })
        .then((response) => {
          const { data } = response.data;
          setOptions([...options, ...data]);
        })
        .finally(() => {
          setLoading(false);
        });
    }, delay),
  ).current;

  const handleSearch = (e: React.FormEvent<HTMLInputElement>): void => {
    delaySearch(e.currentTarget.value);
  };
  const handleRemove = (): void => {
    setOptionSelected(undefined);
    control.setValue(name, null);
  };

  const handleClear = (): void => {
    if (inputRefVisible.current) {
      inputRefVisible.current.value = '';
      inputRefVisible.current?.focus();
    }
    setOptions([]);
  };

  const handleItemSelected = useCallback((itemSelected: Option): void => {
    if (inputRefVisible.current) {
      inputRefVisible.current.value = '';
      inputRefVisible.current.focus();
      setOptionSelected(itemSelected);
      control.setValue(name, itemSelected.id);
    }
    setOptions([]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (defaultOption) {
      setOptionSelected(defaultOption);
    }
    // eslint-disable-next-line
  }, [defaultOption]);

  useEffect(() => {
    setOptionSelected(undefined);
  }, [control]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={optionSelected?.id || ''}
      render={() => (
        <>
          <S.Container
            style={containerStyle}
            isFocused={isFocused}
            isFilled={false}
            isErrored={!!errors}
          >
            <input name={name} hidden ref={register} onChange={handleSearch} />
            {Icon && <Icon size={20} />}
            {optionSelected && (
              <S.ItemSelectedList>
                <S.ItemSelectedContainer>
                  <S.ItemSelected>{optionSelected?.name}</S.ItemSelected>
                  <S.IconRemoveItemSelected onClick={() => handleRemove()}>
                    <MdCancel size={16} color="var(--primary)" />
                  </S.IconRemoveItemSelected>
                </S.ItemSelectedContainer>
              </S.ItemSelectedList>
            )}
            <input
              type="text"
              ref={inputRefVisible}
              onFocus={(e) => {
                setIsFocused(true);
                e.target.select();
              }}
              onBlur={handleInputBlur}
              onChange={handleSearch}
            />
            {errors && (
              <S.Error title={errors?.message || ''}>
                <FiAlertCircle color="#c53030" size={20} />
              </S.Error>
            )}
            {loading && <S.Spinner />}
            {options.length > 0 && (
              <S.ButtonLimpar
                type="button"
                title="Limpar"
                onClick={handleClear}
              >
                <MdCancel size={24} />
              </S.ButtonLimpar>
            )}
          </S.Container>
          <S.ListItens>
            {options &&
              options.map((opt: Option) => (
                <S.Item
                  onClick={() => handleItemSelected(opt)}
                  key={String(opt.id)}
                >
                  <strong>{opt.name}</strong>
                </S.Item>
              ))}
          </S.ListItens>
        </>
      )}
    />
  );
};

export default AutoComplete;
