import React from 'react';

import { ImUsers } from 'react-icons/im';

import { useAuth } from '../../../../hooks/auth';

import { KpiBox } from '../../../../components';

import * as S from './styles';

const DashboardHeader: React.FC = () => {
  const { usersOnline } = useAuth();

  return (
    <S.Container>
      <KpiBox
        header="Quantidade"
        body={usersOnline?.length || ''}
        footer={
          usersOnline && usersOnline?.length > 1
            ? 'Usuários Online'
            : 'Usuário Online'
        }
        icon={<ImUsers size={170} />}
      />
    </S.Container>
  );
};

export default DashboardHeader;
