import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { addDays } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { MdChat } from 'react-icons/md';

import { KpiBox } from '../../../components';

import LeftSide from './LeftSide';
import RightSide from './RightSide';

import { useChat } from '../../../hooks/chat';

import api from '../../../services/api';

import * as S from './styles';

interface SessionMessage {
  id: string;
  phone: string;
  contact_name: string;
  last_message: string;
  current?: {
    name: string;
  };
  closed?: {
    name: string;
  };
  department?: {
    name: string;
  };
  transfer?: {
    name: string;
  };
  rating?: string;
  updated_at: string;
}

interface Paginate {
  data: SessionMessage[];
  page: number;
  per_page: number;
  last_page: number;
  total: number;
}

type sessionsType = 'started' | 'waiting' | 'progress' | 'finished' | 'expired';

const SessionMessageResume: React.FC = () => {
  const { updateCurrentSession } = useChat();
  const [sessionStarted, setSessionStarted] = useState<Paginate>();
  const [sessionWaiting, setSessionWaiting] = useState<Paginate>();
  const [sessionProgress, setSessionProgress] = useState<Paginate>();
  const [sessionFinished, setSessionFinished] = useState<Paginate>();
  const [sessionExpired, setSessionExpired] = useState<Paginate>();

  const [sessionType, setSessionType] = useState<sessionsType>('waiting');

  const loadSessionStarted = useCallback(async () => {
    const sessionResponse = await api.get<Paginate>(
      '/message/session/started',
      {
        params: {
          page: 1,
          perPage: 20,
          start_date: zonedTimeToUtc(
            addDays(new Date().setHours(0, 0, 0, 0), -7),
            'America/Sao_Paulo',
          ),
          end_date: zonedTimeToUtc(
            new Date().setHours(23, 59, 59, 0),
            'America/Sao_Paulo',
          ),
        },
      },
    );

    setSessionStarted(sessionResponse.data);
  }, []);

  const loadSessionWaiting = useCallback(async () => {
    const sessionResponse = await api.get<Paginate>(
      '/message/session/waiting',
      {
        params: {
          page: 1,
          perPage: 20,
          start_date: zonedTimeToUtc(
            addDays(new Date().setHours(0, 0, 0, 0), -7),
            'America/Sao_Paulo',
          ),
          end_date: zonedTimeToUtc(
            new Date().setHours(23, 59, 59, 0),
            'America/Sao_Paulo',
          ),
        },
      },
    );

    setSessionWaiting(sessionResponse.data);
  }, []);

  const loadSessionProgress = useCallback(async () => {
    const sessionResponse = await api.get<Paginate>(
      '/message/session/progress',
      {
        params: {
          page: 1,
          perPage: 20,
          start_date: zonedTimeToUtc(
            addDays(new Date().setHours(0, 0, 0, 0), -7),
            'America/Sao_Paulo',
          ),
          end_date: zonedTimeToUtc(
            new Date().setHours(23, 59, 59, 0),
            'America/Sao_Paulo',
          ),
        },
      },
    );

    setSessionProgress(sessionResponse.data);
  }, []);

  const loadSessionFinished = useCallback(async () => {
    const { data } = await api.get<Paginate>('/message/session/finished', {
      params: {
        page: 1,
        perPage: 20,
        start_date: zonedTimeToUtc(
          addDays(new Date().setHours(0, 0, 0, 0), -7),
          'America/Sao_Paulo',
        ),
        end_date: zonedTimeToUtc(
          new Date().setHours(23, 59, 59, 0),
          'America/Sao_Paulo',
        ),
      },
    });

    if (data) {
      setSessionFinished(data);
    }
  }, []);

  const loadSessionExpired = useCallback(async () => {
    const sessionResponse = await api.get<Paginate>(
      '/message/session/expired',
      {
        params: {
          page: 1,
          perPage: 20,
          start_date: zonedTimeToUtc(
            addDays(new Date().setHours(0, 0, 0, 0), -7),
            'America/Sao_Paulo',
          ),
          end_date: zonedTimeToUtc(
            new Date().setHours(23, 59, 59, 0),
            'America/Sao_Paulo',
          ),
        },
      },
    );

    setSessionExpired(sessionResponse.data);
  }, []);

  useEffect(() => {
    loadSessionStarted();
    loadSessionWaiting();
    loadSessionProgress();
    loadSessionFinished();
    loadSessionExpired();
  }, [
    loadSessionExpired,
    loadSessionFinished,
    loadSessionProgress,
    loadSessionStarted,
    loadSessionWaiting,
  ]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      loadSessionStarted();
      loadSessionWaiting();
      loadSessionProgress();
      loadSessionFinished();
      loadSessionExpired();
    }, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, [
    loadSessionExpired,
    loadSessionFinished,
    loadSessionProgress,
    loadSessionStarted,
    loadSessionWaiting,
  ]);

  const handleSessionStarted = useCallback(() => {
    setSessionType('started');
    updateCurrentSession(undefined);
  }, [updateCurrentSession]);

  const handleSessionWaiting = useCallback(() => {
    setSessionType('waiting');
    updateCurrentSession(undefined);
  }, [updateCurrentSession]);

  const handleSessionProgress = useCallback(() => {
    setSessionType('progress');
    updateCurrentSession(undefined);
  }, [updateCurrentSession]);

  const handleSessionFinished = useCallback(() => {
    setSessionType('finished');
    updateCurrentSession(undefined);
  }, [updateCurrentSession]);

  const handleSessionExpired = useCallback(() => {
    setSessionType('expired');
    updateCurrentSession(undefined);
  }, [updateCurrentSession]);

  const renderLeft = useMemo<React.ReactElement>(() => {
    switch (sessionType) {
      case 'started':
        return (
          <LeftSide
            sessionMessages={sessionStarted?.data || ({} as SessionMessage[])}
            sessionType={sessionType}
          />
        );
        break;
      case 'progress':
        return (
          <LeftSide
            sessionMessages={sessionProgress?.data || ({} as SessionMessage[])}
            sessionType={sessionType}
          />
        );
        break;
      case 'finished':
        return (
          <LeftSide
            sessionMessages={sessionFinished?.data || ({} as SessionMessage[])}
            sessionType={sessionType}
          />
        );
        break;
      case 'expired':
        return (
          <LeftSide
            sessionMessages={sessionExpired?.data || ({} as SessionMessage[])}
            sessionType={sessionType}
          />
        );
        break;
      default:
        return (
          <LeftSide
            sessionMessages={sessionWaiting?.data || ({} as SessionMessage[])}
            sessionType={sessionType}
          />
        );
    }
  }, [
    sessionExpired?.data,
    sessionFinished?.data,
    sessionProgress?.data,
    sessionStarted?.data,
    sessionType,
    sessionWaiting?.data,
  ]);

  return (
    <S.Container>
      <S.Header>
        <S.SessionButton type="button" onClick={handleSessionStarted}>
          <KpiBox
            header="Quantidade"
            body={sessionStarted?.total || '0'}
            footer={
              sessionStarted?.data && sessionStarted?.data?.length > 1
                ? 'Sessões iniciadas'
                : 'Sessão iniciada'
            }
            icon={<MdChat size={100} />}
          />
        </S.SessionButton>

        <S.SessionButton onClick={handleSessionWaiting}>
          <KpiBox
            header="Quantidade"
            body={sessionWaiting?.total || '0'}
            footer={
              sessionWaiting?.data && sessionWaiting?.data?.length > 1
                ? 'Sessões em espera'
                : 'Sessão em espera'
            }
            icon={<MdChat size={100} />}
          />
        </S.SessionButton>

        <S.SessionButton onClick={handleSessionProgress}>
          <KpiBox
            header="Quantidade"
            body={sessionProgress?.total || '0'}
            footer={
              sessionProgress?.data && sessionProgress?.data?.length > 1
                ? 'Sessões em atendimento'
                : 'Sessão em atendimento'
            }
            icon={<MdChat size={100} />}
          />
        </S.SessionButton>

        <S.SessionButton onClick={handleSessionFinished}>
          <KpiBox
            header="Quantidade"
            body={sessionFinished?.total || '0'}
            footer={
              sessionFinished?.data && sessionFinished?.data?.length > 1
                ? 'Sessões finalizada'
                : 'Sessão finalizada'
            }
            icon={<MdChat size={100} />}
          />
        </S.SessionButton>

        <S.SessionButton onClick={handleSessionExpired}>
          <KpiBox
            header="Quantidade"
            body={sessionExpired?.total || '0'}
            footer={
              sessionExpired?.data && sessionExpired?.data?.length > 1
                ? 'Sessões expiradas'
                : 'Sessão expirada'
            }
            icon={<MdChat size={100} />}
          />
        </S.SessionButton>
      </S.Header>

      <S.Body>
        {renderLeft}
        <RightSide />
      </S.Body>
    </S.Container>
  );
};

export default SessionMessageResume;
