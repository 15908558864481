import React from 'react';

import { useChat } from '../../../../../hooks/chat';

import avartarImgDefault from '../../../../../assets/avatar.png';

import * as S from './styles';

const ChatHeader: React.FC = () => {
  const { currentSession } = useChat();

  return (
    <S.Container>
      <S.HeadInfo>
        {currentSession?.id && (
          <>
            <img src={avartarImgDefault} alt="profile" />
            <div>{currentSession?.contact_name || currentSession?.phone}</div>
          </>
        )}
      </S.HeadInfo>
    </S.Container>
  );
};

export default ChatHeader;
