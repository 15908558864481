import React, { createContext, useCallback, useState, useContext } from 'react';

interface SiedeBarState {
  isOpened: boolean;
}

interface SideBarContextData {
  isOpened: boolean;
  onToogleOpenSideBar(): void;
}

const SiedeBarContext = createContext<SideBarContextData>(
  {} as SideBarContextData,
);

const SiedeBarProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<SiedeBarState>(() => {
    const isSideBarOpened = localStorage.getItem(
      '@smartMessage:isSideBarOpened',
    );
    if (isSideBarOpened && isSideBarOpened === 'true') {
      return { isOpened: true } as SiedeBarState;
    }
    return { isOpened: false } as SiedeBarState;
  });

  const onToogleOpenSideBar = useCallback(() => {
    const isOpened = !data.isOpened;
    localStorage.setItem('@smartMessage:isSideBarOpened', String(isOpened));
    setData({ isOpened });
  }, [data.isOpened]);

  return (
    <SiedeBarContext.Provider
      value={{ isOpened: data.isOpened, onToogleOpenSideBar }}
    >
      {children}
    </SiedeBarContext.Provider>
  );
};

function useSiedeBar(): SideBarContextData {
  const context = useContext(SiedeBarContext);

  if (!context) {
    throw new Error('useSiedeBar must be used within an SiedeBarProvider');
  }

  return context;
}

export { SiedeBarProvider, useSiedeBar };
