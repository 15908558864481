import styled, { css } from 'styled-components';

import { FaMapMarkerAlt } from 'react-icons/fa';

interface ContainerProps {
  messageType: 'Inbound' | 'Outbound';
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  margin-bottom: 10px;

  justify-content: ${(props) =>
    props.messageType === 'Inbound' ? 'flex-start' : 'flex-end'};
`;

export const FileContainer = styled.div<ContainerProps>`
  width: 336px;
  height: 85px;
  padding: 4px;
  border-radius: 8px;

  background-color: ${(props) =>
    props.messageType === 'Inbound'
      ? 'var(--chat-message-inbound)'
      : 'var(--chat-message-outbound)'};
`;

export const Header = styled.div`
  height: 60px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  > a {
    display: flex;
    width: 100%;
    height: 100%;
    cursor: pointer;
    align-items: center;
    margin-left: 8px;
    text-decoration: none;
    color: var(--text);
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const MessageDate = styled.div`
  font-size: 11px;
  color: #999;
  margin-top: 4px;
`;

const IconCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 4px;
  width: 40px;
  height: 40px;
  margin-right: 8px;
  color: var(--text-header);
`;

export const IconFile = styled(FaMapMarkerAlt)`
  ${IconCss}
`;
