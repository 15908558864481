import React, { createContext, useCallback, useContext, useState } from 'react';

import { io, Socket } from 'socket.io-client';

import { useAuth } from './auth';

interface SocketContextData {
  socket: Socket;
  disconnectSocket(): void;
  connectSocket(): void;
}

const SocketContext = createContext<SocketContextData>({} as SocketContextData);

const SocketProvider: React.FC = ({ children }) => {
  //  const socket = useRef<Socket>();

  // const connectSocket = useCallback(() => {
  //   socket.current = io(`${process.env.REACT_APP_SOCKET_URL}`, {
  //     query: {
  //       user_id: user.id,
  //       department_id: user.department_id,
  //     },
  //   });
  // }, [user?.department_id, user?.id]);

  const [socket, setSocket] = useState<Socket>();

  const { user } = useAuth();

  const connectSocket = useCallback(() => {
    const conn = io(`${process.env.REACT_APP_SOCKET_URL}`, {
      query: {
        tenant_id: user?.tenant_id,
        user_id: user?.id,
        user_name: user?.name,
        department_id: user?.department_id,
        department_name: user?.department?.name,
        avatar_url: user?.avatar_url,
      },
    });

    setSocket(conn);
  }, [
    user?.avatar_url,
    user?.department?.name,
    user?.department_id,
    user?.id,
    user?.name,
    user?.tenant_id,
  ]);

  const disconnectSocket = useCallback(() => {
    socket?.disconnect();
  }, [socket]);

  return (
    <SocketContext.Provider
      value={{
        socket: socket as Socket,
        disconnectSocket,
        connectSocket,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

function useSocket(): SocketContextData {
  const context = useContext(SocketContext);

  if (!context) {
    throw new Error('useSocket must be used within an SocketProvider');
  }

  return context;
}

export { SocketProvider, useSocket };
