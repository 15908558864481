import React from 'react';
import { FieldError } from 'react-hook-form';

import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';

import { Container, Error } from './styles';

type RefReturn =
  | string
  | ((instance: HTMLSelectElement | null) => void)
  | React.RefObject<HTMLSelectElement>
  | null
  | undefined;

interface Option {
  id: string | undefined;
  name?: string;
}

interface InputProps {
  name: string;
  containerStyle?: object;
  options: Option[];
  icon?: React.ComponentType<IconBaseProps>;
  register: RefReturn;
  errors: FieldError | undefined;
}

const Select: React.FC<InputProps> = ({
  name,
  options,
  containerStyle = {},
  icon: Icon,
  register,
  errors,
  ...rest
}) => {
  return (
    <Container style={containerStyle}>
      {Icon && <Icon size={20} />}
      <select {...rest} ref={register} name={name}>
        {options.map((item) => (
          <option key={String(item.id)} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
      {errors && (
        <Error title={errors.message || ''}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </Container>
  );
};

export default Select;
