import React from 'react';
import { parseISO, format } from 'date-fns';
import ReactAudioPlayer from 'react-audio-player';

import * as S from './styles';

interface AudioPlayerProps {
  file: string | undefined;
  type: 'Inbound' | 'Outbound';
  messageDate: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  file,
  type,
  messageDate,
}) => {
  return (
    <S.Container messageType={type}>
      <S.AudioContainer messageType={type}>
        <S.Header messageType={type}>
          <ReactAudioPlayer src={file} controls />
        </S.Header>
        <S.Footer>
          <S.MessageDate>
            {format(parseISO(messageDate), 'dd/MM/yyyy HH:mm')}
          </S.MessageDate>
        </S.Footer>
      </S.AudioContainer>
    </S.Container>
  );
};

export default AudioPlayer;
