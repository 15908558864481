import React from 'react';

import Header from './Header';
import Body from './Body';

import * as S from './styles';

const Dashboard: React.FC = () => {
  return (
    <S.Container>
      <Header />
      <Body />
    </S.Container>
  );
};

export default Dashboard;
