import React from 'react';

import whatsappIntro from '../../../../assets/whatsappIntro.jpg';

import { Container, Line, LinkContainer, LaptopIcon } from './styles';

const ChatIntro: React.FC = () => {
  return (
    <Container>
      <img src={whatsappIntro} alt="Whatsapp" />
      <h1>Mantenha seu telefone conectado</h1>
      <h2>
        O Smart Message se conecta ao seu telefone para sincronizar mensagens.
        Para reduzir o uso de dados, conecte seu telefone ao Wi-Fi.
      </h2>

      <LinkContainer>
        <Line />
        <div>
          <LaptopIcon />
          <h1>
            O Smart Message está disponível para Windows e Mac.
            <a href="/">Clique aqui.</a>
          </h1>
        </div>
      </LinkContainer>
    </Container>
  );
};

export default ChatIntro;
