import styled from 'styled-components';

interface ContainerProps {
  messageType: 'Inbound' | 'Outbound';
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  margin-bottom: 10px;

  justify-content: ${(props) =>
    props.messageType === 'Inbound' ? 'flex-start' : 'flex-end'};
`;

export const VideoContainer = styled.div<ContainerProps>`
  width: 336px;
  height: 215px;
  padding: 4px;
  border-radius: 8px;

  background-color: ${(props) =>
    props.messageType === 'Inbound'
      ? 'var(--chat-message-inbound)'
      : 'var(--chat-message-outbound)'};
`;

export const Header = styled.div`
  height: 186px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 4px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const MessageDate = styled.div`
  font-size: 11px;
  color: #999;
  margin-top: 4px;
`;
