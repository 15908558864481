import styled, { css } from 'styled-components';

import { MdChat } from 'react-icons/md';

export const Container = styled.div`
  height: 100%;
  width: 35%;
  max-width: 415px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--chat-border-color);
  background: var(--chat-background);
`;

export const SessionButton = styled.button`
  width: 100%;
  border: none;
  background: transparent;
`;

export const Header = styled.header`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  border-bottom: 1px solid var(--chat-border-color);
  background: var(--primary);

  > img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
  }

  > div {
    flex: 1;
    font-size: 17px;
    color: var(--text-header);
    height: 40px;
    line-height: 40px;
    margin-left: 20px;
    /* display: flex; */

    .online {
      width: 7px;
      height: 7px;
      border-radius: 3.5px;
      background: green;
      position: relative;
      z-index: 100;
      top: 9px;
      right: 17px;
    }
  }

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
    background-color: var(--chat-badge);
    font-size: 10px;
    margin-left: -25px;
    margin-top: -25px;
  }
`;

export const SearchTab = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--header);
  padding: 5px 15px;

  > div {
    display: flex;
    align-items: center;
    background: var(--chat-list);
    border-radius: 20px;
    height: 40px;
    padding: 0 10px;

    > input {
      flex: 1;
      border: none;
      color: var(--primary);
      outline: 0;
      background-color: transparent;
      margin-left: 10px;
    }
  }
`;

export const ChatList = styled.div`
  flex: 1;
  background: var(--chat-list);
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--scroll);
  }
`;

const IconCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 4px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  cursor: pointer;
  color: var(--text-header);

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

export const IconChat = styled(MdChat)`
  ${IconCss}
`;
