import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaTags } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';
import {
  Autocomplete,
  HeaderBackground,
  HeaderInfo,
  Input,
  Button,
  Label,
  FormGroup,
} from '../../../components';

import { useToast } from '../../../hooks/toast';

import api from '../../../services/api';

import * as S from './styles';

interface FormData {
  name: string;
  email: string;
  department_id: string;
  password: string;
  confirm_password: string;
}

interface Department {
  id: string;
  menu_number: string;
  name: string;
}

interface User {
  id: string;
  name: string;
  department_id: string;
  department: Department;
  email: string;
  password: string;
}

interface RouterParams {
  id?: string | undefined;
}

const UserForm: React.FC = () => {
  const { id } = useParams<RouterParams>();
  const history = useHistory();

  let schema: Yup.ObjectSchema;

  if (id) {
    schema = Yup.object().shape({
      name: Yup.string().required('Relatório obrigatório'),
      department_id: Yup.string(),
      email: Yup.string()
        .required('Email obrigatório')
        .email('Digite um e-mail válido'),
    });
  } else {
    schema = Yup.object().shape({
      name: Yup.string().required('Relatório obrigatório'),
      department_id: Yup.string(),
      email: Yup.string()
        .required('Email obrigatório')
        .email('Digite um e-mail válido'),
      password: Yup.string().required('Senha obrigatória'),
      confirm_password: Yup.string()
        .required('Confirmação de senha obrigatoria')
        .oneOf([Yup.ref('password'), 'Confirmação invalida']),
    });
  }

  const { register, handleSubmit, errors, reset, control } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<User>();

  const onSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        if (id) {
          const response = await api.put<User>(`/users/${id}`, data);

          setUser(response.data);
        } else {
          await api.post('/users', data);

          reset();

          history.push('/users');
        }

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Salvo com sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, history, id, reset],
  );

  useEffect(() => {
    if (id) {
      api.get<User>(`/users/${id}`).then((response) => {
        const { data } = response;
        reset(data);
        setUser(data);
      });
    }
  }, [id, reset]);

  const renderPassword = useCallback(() => {
    return (
      <>
        <FormGroup>
          <Label>Senha</Label>
          <Input
            type="password"
            register={register}
            errors={errors.password}
            name="password"
            placeholder="Senha"
            autoComplete="off"
          />
        </FormGroup>

        <FormGroup>
          <Label>Confirmação Senha</Label>
          <Input
            type="password"
            register={register}
            errors={errors.confirm_password}
            name="confirm_password"
            placeholder="Confirmação da senha"
            autoComplete="off"
          />
        </FormGroup>
      </>
    );
  }, []);

  return (
    <S.Container>
      <HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/users">
              <MdKeyboardBackspace size={24} color="#fff" />
              Usuários
            </Link>
          </S.BackLink>
          <HeaderInfo
            title={id ? `Editando: ${user?.name}` : 'Novo Usuário'}
            icon={FaTags}
          />
        </S.HeaderPageDatail>
      </HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>Usuário</Label>
            <Input
              type="text"
              register={register}
              errors={errors.name}
              name="name"
              placeholder="Usuário"
            />
          </FormGroup>

          <FormGroup>
            <Label>Email</Label>
            <Input
              type="text"
              register={register}
              errors={errors.email}
              name="email"
              placeholder="Email"
            />
          </FormGroup>

          <FormGroup>
            <Label>Departamento</Label>
            <Autocomplete
              register={register}
              errors={errors.department_id}
              control={control}
              name="department_id"
              placeholder="Selecione uma workspace"
              url="/departments"
              defaultOption={user?.department}
            />
          </FormGroup>

          {id ? <></> : renderPassword()}

          <Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};

export default UserForm;
