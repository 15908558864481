import React from 'react';
import { parseISO, format } from 'date-fns';

import * as S from './styles';

interface FileDownloadProps {
  file: string | undefined;
  type: 'Inbound' | 'Outbound';
  fileName?: string;
  messageDate: string;
}

const FileDownload: React.FC<FileDownloadProps> = ({
  file,
  type,
  fileName,
  messageDate,
}) => {
  return (
    <S.Container messageType={type}>
      <S.FileContainer messageType={type}>
        <S.Header>
          <a href={file} download={fileName}>
            <S.IconFile />
            {fileName}
          </a>
        </S.Header>
        <S.Footer>
          <S.MessageDate>
            {format(parseISO(messageDate), 'dd/MM/yyyy HH:mm')}
          </S.MessageDate>
        </S.Footer>
      </S.FileContainer>
    </S.Container>
  );
};

export default FileDownload;
