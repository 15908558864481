import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Form from './Form';

const AutoAnswers: React.FC = () => {
  return (
    <Switch>
      <Route path="/answers" component={List} isPrivate exact />
      <Route path="/answers/add" component={Form} isPrivate />
      <Route path="/answers/edit/:id" component={Form} isPrivate />
    </Switch>
  );
};

export default AutoAnswers;
