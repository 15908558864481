import React from 'react';
import ReactVideoPlayer from 'react-player';
import { parseISO, format } from 'date-fns';

import * as S from './styles';

interface VideoPlayerProps {
  file: string | undefined;
  type: 'Inbound' | 'Outbound';
  messageDate: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  file,
  type,
  messageDate,
}) => {
  return (
    <S.Container messageType={type}>
      <S.VideoContainer messageType={type}>
        <S.Header>
          <ReactVideoPlayer url={file} controls width="100%" height="100%" />
        </S.Header>
        <S.Footer>
          <S.MessageDate>
            {format(parseISO(messageDate), 'dd/MM/yyyy HH:mm')}
          </S.MessageDate>
        </S.Footer>
      </S.VideoContainer>
    </S.Container>
  );
};

export default VideoPlayer;
