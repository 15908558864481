import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 35%;
  max-width: 415px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--chat-border-color);
  background: var(--chat-background);
`;

export const Header = styled.header`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  border-bottom: 1px solid var(--chat-border-color);
  background: var(--primary);

  > div {
    flex: 1;
    font-size: 17px;
    color: var(--text-header);
    height: 40px;
    line-height: 40px;
  }
`;

export const ChatList = styled.div`
  flex: 1;
  background: var(--chat-list);
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--scroll);
  }
`;

export const SessionButton = styled.button`
  width: 100%;
  border: none;
  background: transparent;
`;
