import React from 'react';

import { useChat } from '../../../../hooks/chat';

import avartarImgDefault from '../../../../assets/avatar.png';

import * as S from './styles';

const ChatHeader: React.FC = () => {
  const {
    currentSession,
    updateCurrentSessionClosed,
    updateCurrentSessionTransfer,
  } = useChat();

  return (
    <S.Container>
      <S.HeadInfo>
        <img src={avartarImgDefault} alt="profile" />
        <div>{currentSession?.contact_name || currentSession?.phone}</div>
      </S.HeadInfo>
      <S.IconContainer>
        <S.IconTransfer
          onClick={() => updateCurrentSessionTransfer(currentSession)}
        />
        <S.IconClose
          onClick={() => updateCurrentSessionClosed(currentSession)}
        />
      </S.IconContainer>
    </S.Container>
  );
};

export default ChatHeader;
