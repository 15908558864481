import React, { createContext, useCallback, useState, useContext } from 'react';
import { isBefore } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import api from '../services/api';

interface User {
  tenant_id: string;
  id: string;
  name: string;
  email: string;
  avatar_url: string;
  role: string;
  department_id: string;
  department: {
    id: string;
    name: string;
  };
}

interface Permission {
  id: string;
  name: string;
  category: string;
  report_id: string;
}
interface AuthState {
  token: string;
  user: User;
  permissions: Permission[];
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface IUsersOnline {
  // [key: string]: {
  tenant_id: string;
  user_id: string;
  user_name: string;
  department_id: string;
  department_name: string;
  avatar_url: string;
  // }
}

interface AuthContextData {
  user: User;
  usersOnline: IUsersOnline[];
  permissions: Permission[];
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
  updateUsersOnline(users: IUsersOnline[]): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@smartMessage:token');
    const tokenExpires = localStorage.getItem('@smartMessage:tokenExpires');
    const user = localStorage.getItem('@smartMessage:user');
    const permissions = localStorage.getItem('@smartMessage:permissions');

    if (!tokenExpires) {
      // Para evitar erros caso não tenha tokenExpires
      localStorage.removeItem('@smartMessage:token');
      localStorage.removeItem('@smartMessage:tokenExpires');
      localStorage.removeItem('@smartMessage:user');
      localStorage.removeItem('@smartMessage:permissions');

      return {} as AuthState;
    }

    if (
      tokenExpires &&
      isBefore(
        zonedTimeToUtc(tokenExpires, 'America/Sao_Paulo'),
        zonedTimeToUtc(new Date(), 'America/Sao_Paulo'),
      )
    ) {
      localStorage.removeItem('@smartMessage:token');
      localStorage.removeItem('@smartMessage:tokenExpires');
      localStorage.removeItem('@smartMessage:user');
      localStorage.removeItem('@smartMessage:permissions');

      return {} as AuthState;
    }

    if (token && user && permissions) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return {
        token,
        user: JSON.parse(user),
        permissions: JSON.parse(permissions),
      };
    }

    return {} as AuthState;
  });

  const [usersOnline, setUsersOnline] = useState<IUsersOnline[]>([
    {},
  ] as IUsersOnline[]);

  const updateUsersOnline = useCallback((users: IUsersOnline[]) => {
    if (users.length > 0) {
      setUsersOnline(users);
    }
  }, []);

  const signIn = useCallback(async ({ email, password }) => {
    const res = await api.post('sessions', { email, password });

    const { token, user, permissions, expires_in } = res.data;

    localStorage.setItem('@smartMessage:token', token);
    localStorage.setItem('@smartMessage:tokenExpires', expires_in);
    localStorage.setItem('@smartMessage:user', JSON.stringify(user));
    localStorage.setItem(
      '@smartMessage:permissions',
      JSON.stringify(permissions || []),
    );

    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, user, permissions });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@smartMessage:token');
    localStorage.removeItem('@smartMessage:tokenExpires');
    localStorage.removeItem('@smartMessage:user');
    localStorage.removeItem('@smartMessage:permissions');
    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      setData({
        ...data,
        token: data.token,
        user,
        permissions: data.permissions,
      });
      localStorage.setItem('@smartMessage:user', JSON.stringify(user));
    },
    [setData, data],
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        usersOnline,
        permissions: data.permissions,
        signIn,
        signOut,
        updateUser,
        updateUsersOnline,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
