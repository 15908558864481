import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {
  Button,
  Label,
  FormGroup,
  Select,
  TextArea,
} from '../../../../components';

import { useChat } from '../../../../hooks/chat';
import { useToast } from '../../../../hooks/toast';

import api from '../../../../services/api';

import avartarImgDefault from '../../../../assets/avatar.png';

import * as S from './styles';

interface FormData {
  solved: string;
  observation: string;
}

interface SessionMessage {
  id: string;
  phone: string;
  contact_name: string;
  last_message: string;
  updated_at: string;
}

interface Paginate {
  data: SessionMessage[];
  page: number;
  per_page: number;
  last_page: number;
  total: number;
}

const schema = Yup.object().shape({
  solved: Yup.string().required(),
  observation: Yup.string(),
});

const SessionClosed: React.FC = () => {
  const {
    currentSession,
    currentSessionClosed,
    updateCurrentSessionClosed,
    updateCurrentSessionTransfer,
    updateCurrentSession,
    updateSessionMessage,
  } = useChat();

  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, errors } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        const response = await api.put<FormData>(
          `/message/session/close/${currentSessionClosed?.id}`,
          { ...data, solved: data.solved === 'SIM' },
        );

        if (response.data) {
          const sessionMessage = await api.get<Paginate>(
            '/message/session/user',
            {
              params: {
                page: 1,
                per_page: 20,
              },
            },
          );

          updateSessionMessage(sessionMessage.data);
        }

        updateCurrentSessionClosed({} as SessionMessage);
        updateCurrentSessionTransfer({} as SessionMessage);
        updateCurrentSession({} as SessionMessage);

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Finalizado com sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },
    [
      addToast,
      currentSessionClosed?.id,
      updateCurrentSession,
      updateCurrentSessionClosed,
      updateCurrentSessionTransfer,
      updateSessionMessage,
    ],
  );

  const handleCancel = useCallback(() => {
    updateCurrentSessionClosed({} as SessionMessage);
  }, [updateCurrentSessionClosed]);

  return (
    <S.Container>
      <S.ContainerHeader>
        <S.HeadInfo>
          <img src={avartarImgDefault} alt="profile" />
          <div>{currentSession?.contact_name || currentSession?.phone}</div>
        </S.HeadInfo>
      </S.ContainerHeader>

      <S.ContainerBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>Resolvido</Label>
            <Select
              register={register}
              name="solved"
              options={[
                { id: 'SIM', name: 'SIM' },
                { id: 'NAO', name: 'NAO' },
              ]}
              errors={errors.solved}
            />
          </FormGroup>

          <FormGroup>
            <Label>Observação</Label>
            <TextArea
              register={register}
              errors={errors.observation}
              name="observation"
              placeholder="Alguma observação? "
              cols={80}
              rows={15}
            />
          </FormGroup>

          <S.SubimitContainer>
            <Button
              variant="secondary"
              type="button"
              title="Cancelar Tranferencia"
              onClick={handleCancel}
            >
              Cancelar
            </Button>

            <Button
              variant="primary"
              disabled={loading}
              loading={loading}
              type="submit"
              title="Finalizar"
            >
              Finalizar
            </Button>
          </S.SubimitContainer>
        </form>
      </S.ContainerBody>
    </S.Container>
  );
};

export default SessionClosed;
