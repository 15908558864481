import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 15px;
  padding: 8px;
`;

export const Body = styled.div`
  display: flex;
  height: 100%;
  padding: 8px;
  overflow: auto;
`;

export const SessionButton = styled.button`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  background: transparent;
  width: 100%;
  border: none;
  overflow: hidden;
  color: inherit;
`;
