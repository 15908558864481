import React, { useState, useCallback, useEffect } from 'react';

import { Button } from '../../../../components';

import { useChat } from '../../../../hooks/chat';
import { useAuth } from '../../../../hooks/auth';
import { useToast } from '../../../../hooks/toast';

import api from '../../../../services/api';

import avartarImgDefault from '../../../../assets/avatar.png';

import * as S from './styles';

interface SessionMessage {
  id: string;
  phone: string;
  contact_name: string;
  last_message: string;
  current?: {
    name: string;
  };
  closed?: {
    name: string;
  };
  department?: {
    name: string;
  };
  transfer?: {
    name: string;
  };
  rating?: string;
  updated_at: string;
}
interface User {
  id: string;
  name: string;
  department: {
    id: string;
    name: string;
  };
  online: boolean;
  avatar_url: string | null;
}

interface Department {
  id: string;
  name: string;
}

interface PaginateUser {
  data: User[];
  page: number;
  per_page: number;
  last_page: number;
  total: number;
}

interface PaginateDepartment {
  data: Department[];
  page: number;
  per_page: number;
  last_page: number;
  total: number;
}

interface Paginate {
  data: SessionMessage[];
  page: number;
  per_page: number;
  last_page: number;
  total: number;
}

const SessionTransfer: React.FC = () => {
  const {
    currentSession,
    currentSessionTransfer,
    updateCurrentSessionTransfer,
    updateCurrentSession,
    updateSessionMessage,
  } = useChat();

  const { usersOnline, user } = useAuth();

  const { addToast } = useToast();

  const [users, setUsers] = useState<PaginateUser>();
  const [departments, setDepartments] = useState<PaginateDepartment>();
  const [type, setType] = useState('');
  const [sessionTransfer, setSessionTransfer] = useState('');
  const [currentTransferUser, setCurrentTransferUser] = useState<
    User | undefined
  >();

  const loadUsers = useCallback(async () => {
    const userResponse = await api.get<PaginateUser>('/users', {
      params: {
        page: 1,
        per_page: 20,
      },
    });

    const joinUsers = userResponse.data.data.map((u) => {
      return {
        ...u,
        online: false,
        ...usersOnline.reduce((acc, val) => {
          if (val.user_id === u.id) {
            return {
              online: true,
            };
          }
          return acc;
        }, {}),
      };
    });

    const userDel = joinUsers.findIndex((idx) => idx.id === user.id);

    joinUsers.splice(userDel, 1);

    userResponse.data.data = joinUsers;

    setUsers(userResponse.data);
  }, [user.id, usersOnline]);

  const loadDepartments = useCallback(async () => {
    const departmentResponse = await api.get<PaginateDepartment>(
      '/departments',
      {
        params: {
          page: 1,
          per_page: 20,
        },
      },
    );

    setDepartments(departmentResponse.data);
  }, []);

  useEffect(() => {
    loadUsers();
    loadDepartments();
  }, [loadDepartments, loadUsers]);

  const handleTransferDepartment = useCallback((dep) => {
    setType('department');
    setSessionTransfer(dep);
  }, []);

  const handleTransferUser = useCallback((usr) => {
    setType('user');
    setSessionTransfer(usr.id);
    setCurrentTransferUser(usr);
  }, []);

  const handleCancel = useCallback(() => {
    setType('');
    setSessionTransfer('');
    updateCurrentSessionTransfer({} as SessionMessage);
  }, [updateCurrentSessionTransfer]);

  const handleSubmit = useCallback(async () => {
    if (type === 'department') {
      try {
        const response = await api.put<FormData>(
          `/message/session/transfer/department/${currentSessionTransfer?.id}`,
          { department_id: sessionTransfer },
        );

        if (response.data) {
          const sessionMessage = await api.get<Paginate>(
            '/message/session/user',
            {
              params: {
                page: 1,
                per_page: 20,
              },
            },
          );

          updateSessionMessage(sessionMessage.data);
        }

        updateCurrentSessionTransfer({} as SessionMessage);
        updateCurrentSession({} as SessionMessage);

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Finalizado com sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      }
    }

    if (type === 'user') {
      try {
        const response = await api.put<FormData>(
          `/message/session/transfer/user/${currentSessionTransfer?.id}`,
          {
            department_id: currentTransferUser?.department.id,
            current_user: currentTransferUser?.id,
          },
        );

        if (response.data) {
          const sessionMessage = await api.get<Paginate>(
            '/message/session/user',
            {
              params: {
                page: 1,
                per_page: 20,
              },
            },
          );

          updateSessionMessage(sessionMessage.data);
        }

        updateCurrentSessionTransfer({} as SessionMessage);
        updateCurrentSession({} as SessionMessage);

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Finalizado com sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      }
    }

    setType('');
    setSessionTransfer('');
  }, [
    addToast,
    currentSessionTransfer?.id,
    currentTransferUser?.department.id,
    currentTransferUser?.id,
    sessionTransfer,
    type,
    updateCurrentSession,
    updateCurrentSessionTransfer,
    updateSessionMessage,
  ]);

  return (
    <S.Container>
      <S.ContainerHeader>
        <S.HeadInfo>
          <img src={avartarImgDefault} alt="profile" />
          <div>{currentSession?.contact_name || currentSession?.phone}</div>
        </S.HeadInfo>
      </S.ContainerHeader>

      <S.ContainerBody>
        <S.DepartmentsContainer>
          <span>Setores</span>
          <S.GridContainer>
            {departments?.data.map((item: Department) => (
              <S.ButtonContainer
                key={String(item.id)}
                onClick={() => handleTransferDepartment(item.id)}
                isFocused={sessionTransfer === item.id}
              >
                <S.UserOnline>
                  <S.UserLine>
                    <S.Name>{item.name}</S.Name>
                  </S.UserLine>
                </S.UserOnline>
              </S.ButtonContainer>
            ))}
          </S.GridContainer>
        </S.DepartmentsContainer>

        <S.UsersContainer>
          <span>Usuários</span>
          <S.GridContainer>
            {users?.data.map((item: User) => (
              <S.ButtonContainer
                key={String(item.id)}
                onClick={() => handleTransferUser(item)}
                isFocused={sessionTransfer === item.id}
              >
                <img
                  src={
                    item.avatar_url !== null
                      ? item.avatar_url
                      : avartarImgDefault
                  }
                  alt="Icon"
                />

                <S.UserOnline>
                  <S.UserLine>
                    <S.Name>{item.name}</S.Name>
                  </S.UserLine>

                  <S.Department>
                    <p>{item.department.name}</p>
                    {item.online && <S.Online />}
                  </S.Department>
                </S.UserOnline>
              </S.ButtonContainer>
            ))}
          </S.GridContainer>
        </S.UsersContainer>
      </S.ContainerBody>

      <S.SubimitContainer>
        <Button
          variant="secondary"
          type="button"
          title="Cancelar Tranferencia"
          onClick={handleCancel}
        >
          Cancelar
        </Button>

        <Button
          variant="primary"
          type="button"
          title="Tranferir Atendimento"
          disabled={!type}
          onClick={handleSubmit}
        >
          Transferir
        </Button>
      </S.SubimitContainer>
    </S.Container>
  );
};

export default SessionTransfer;
