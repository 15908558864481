import styled, { css } from 'styled-components';

import { MdArrowBack } from 'react-icons/md';

interface ContainerProps {
  show: boolean;
}

export const Container = styled.div<ContainerProps>`
  /* height: 100%; */
  min-height: 90%;
  width: 35%;
  max-width: 415px;
  position: fixed;
  margin-left: ${(props) => (props.show ? '0' : '-900px')};
  flex-direction: column;
  border-right: 1px solid var(--chat-border-color);
  background: var(--chat-background);
  transition: all ease 0.1s;
`;

export const Header = styled.div`
  display: flex;
  background: var(--primary);
  border-bottom: 1px solid var(--chat-border-color);
  align-items: center;
  padding: 60px 15px 15px 15px;

  > div {
    font-size: 19px;
    height: 40px;
    line-height: 40px;
    flex: 1;
    font-weight: bold;
    color: #fff;
    margin-left: 20px;
  }
`;

const IconCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 4px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  cursor: pointer;
  color: var(--text-header);

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

export const IconBack = styled(MdArrowBack)`
  ${IconCss}
`;

export const ChatList = styled.div`
  flex: 1;
  background: var(--chat-list);
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--scroll);
  }
`;

export const SessionButton = styled.button`
  width: 100%;
  border: none;
  background: transparent;
`;
