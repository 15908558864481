import styled, { css } from 'styled-components';

interface ContainerProps {
  messageType: 'Inbound' | 'Outbound';
}

export const Container = styled.div<ContainerProps>`
  margin-bottom: 10px;
  display: flex;

  justify-content: ${(props) =>
    props.messageType === 'Inbound' ? 'flex-start' : 'flex-end'};
`;

export const MessageContainer = styled.div<ContainerProps>`
  background-color: ${(props) =>
    props.messageType === 'Inbound'
      ? 'var(--chat-message-inbound)'
      : 'var(--chat-message-outbound)'};

  border-radius: 10px;
  box-shadow: 0 1px 0.5px var(--chat-message-shadow);
  display: flex;
  flex-direction: column;
  padding: 3px;
  max-width: 90%;
  color: var(--chat-message-text);
`;

export const Message = styled.div`
  pre {
    font-size: 14px;
    line-height: 18px;
    white-space: pre-line; // Quebra de linha
    margin: 5px 100px 5px 5px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }
`;

export const MessageDate = styled.div`
  font-size: 11px;
  color: var(--chat-message-data);
  margin-right: 5px;
  text-align: right;
  height: 15px;
  margin-top: -15px;
`;
