import React from 'react';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { ThemeProvider } from './theme';
import { ChatProvider } from './chat';
import { SocketProvider } from './socket';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <ThemeProvider>
      <SocketProvider>
        <ChatProvider>
          <ToastProvider>{children}</ToastProvider>
        </ChatProvider>
      </SocketProvider>
    </ThemeProvider>
  </AuthProvider>
);

export default AppProvider;
