import styled from 'styled-components';

interface ContainerProps {
  messageType: 'Inbound' | 'Outbound';
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  margin-bottom: 10px;

  justify-content: ${(props) =>
    props.messageType === 'Inbound' ? 'flex-start' : 'flex-end'};
`;

export const AudioContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 336px;
  height: 85px;
  padding: 4px;
  border-radius: 8px;

  background-color: ${(props) =>
    props.messageType === 'Inbound'
      ? 'var(--chat-message-inbound)'
      : 'var(--chat-message-outbound)'};
`;

export const Header = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  flex: 1;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 0 4px;

  > audio {
    width: 100%;

    ::-webkit-media-controls-panel {
      background-color: ${(props) =>
        props.messageType === 'Inbound'
          ? 'var(--chat-message-inbound)'
          : 'var(--chat-message-outbound)'};
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const MessageDate = styled.div`
  font-size: 11px;
  color: #999;
  margin-top: 4px;
`;

export const Fundo = styled.div`
  height: 60px;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
  position: relative;
  top: 0;
`;
