import React, { createContext, useContext, useCallback } from 'react';
import { uuid } from 'uuidv4';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ToastMsg } from '../components';

interface ToastMessage {
  id: string;
  type?: 'success' | 'error' | 'info' | 'warning' | 'dark' | '';
  title: string;
  description?: string;
}

interface ToastConextData {
  addToast(messages: Omit<ToastMessage, 'id'>): void;
}

const ToastContext = createContext<ToastConextData>({} as ToastConextData);

const ToastProvider: React.FC = ({ children }) => {
  const addToast = useCallback(
    ({ type, title, description }: Omit<ToastMessage, 'id'>) => {
      const options: ToastOptions = {
        toastId: uuid(),
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      };

      switch (type) {
        case 'success':
          toast.success(
            <ToastMsg title={title} description={description} />,
            options,
          );
          break;
        case 'error':
          toast.error(
            <ToastMsg title={title} description={description} />,
            options,
          );
          break;
        case 'info':
          toast.info(
            <ToastMsg title={title} description={description} />,
            options,
          );
          break;
        case 'warning':
          toast.warn(
            <ToastMsg title={title} description={description} />,
            options,
          );
          break;
        case 'dark':
          toast.dark(
            <ToastMsg title={title} description={description} />,
            options,
          );
          break;

        default:
          toast(<ToastMsg title={title} description={description} />, options);
          break;
      }
    },
    [],
  );

  return (
    <ToastContext.Provider
      value={{
        addToast,
      }}
    >
      {children}
      <ToastContainer />
    </ToastContext.Provider>
  );
};

function useToast(): ToastConextData {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
}

export { ToastProvider, useToast };
