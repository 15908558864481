import styled, { css } from 'styled-components';

import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiTransfer } from 'react-icons/bi';

export const Container = styled.div`
  height: 60px;
  display: flex;
  border-bottom: 1px solid var(--chat-border-color);
  justify-content: space-between;
  align-items: center;
  background: var(--primary);
`;

export const HeadInfo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  > img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  > div {
    font-size: 17px;
    color: var(--text-header);
  }
`;

const IconCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 4px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  cursor: pointer;
  color: var(--text-header);

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 12px;
`;

export const IconClose = styled(AiOutlineCloseCircle)`
  ${IconCss}
`;

export const IconTransfer = styled(BiTransfer)`
  ${IconCss}
`;
