import React from 'react';
import { parseISO, format } from 'date-fns';

import { bold } from '../../../../../utils/replaceString';

import * as S from './styles';

interface MessageItemProps {
  message: string;
  messageDate: string;
  messageType: 'Inbound' | 'Outbound';
}

const MessageItem: React.FC<MessageItemProps> = ({
  messageDate,
  message,
  messageType,
}) => {
  return (
    <S.Container messageType={messageType}>
      <S.MessageContainer messageType={messageType}>
        <S.Message
          dangerouslySetInnerHTML={{ __html: `<pre>${bold(message)}</pre>` }}
        />

        <S.MessageDate>
          {format(parseISO(messageDate), 'dd/MM/yyyy HH:mm')}
        </S.MessageDate>
      </S.MessageContainer>
    </S.Container>
  );
};

export default MessageItem;
