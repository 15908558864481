import React from 'react';

import * as S from './styles';
import avartarImgDefault from '../../../../../assets/avatar.png';

interface ChatItemProps {
  name: string;
  time: string;
  message: string;
  isFocused: boolean;
  department?: string;
  closed?: string;
  current?: string;
  rating?: string;
}

const ChatItem: React.FC<ChatItemProps> = ({
  name,
  time,
  message,
  isFocused,
  department,
  closed,
  current,
  rating,
}) => {
  return (
    <S.Container isFocused={isFocused}>
      <img src={avartarImgDefault} alt="Icon" />

      <S.Chats>
        <S.ChatLine>
          <S.Name>{name}</S.Name>
          <S.Date>{time}</S.Date>
        </S.ChatLine>

        <S.LastMessage>
          <p>{message}</p>
        </S.LastMessage>

        {department && !current && <S.SubTitle>{department}</S.SubTitle>}
        {current && !department && <S.SubTitle>{current}</S.SubTitle>}

        {closed && (
          <S.SubTitle>
            {closed} {rating && `- ${rating}`}
          </S.SubTitle>
        )}

        {department && current && (
          <S.SubTitle>
            {current} ({department})
          </S.SubTitle>
        )}
      </S.Chats>
    </S.Container>
  );
};

export default ChatItem;
