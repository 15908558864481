import React, { createContext, useCallback, useState, useContext } from 'react';

interface SessionMessage {
  id: string;
  phone: string;
  contact_name: string;
  last_message: string;
  current?: {
    name: string;
  };
  closed?: {
    name: string;
  };
  department?: {
    name: string;
  };
  transfer?: {
    name: string;
  };
  rating?: string;
  updated_at: string;
}

interface Paginate {
  data: SessionMessage[];
  page: number;
  per_page: number;
  last_page: number;
  total: number;
}

interface Messages {
  id: string;
  session_id: string;
  type: 'Inbound' | 'Outbound';
  message_type: 'text' | 'image' | 'audio' | 'video' | 'file' | 'location';
  message?: string;
  status: string;
  message_date: string;
  file_caption?: string;
  file_url?: string;
  file_url_expiry?: string;
  location_longitude?: string;
  location_latitude?: string;
}

interface ChatContextData {
  sessionMessages: Paginate | undefined;
  messages: Messages[];
  newMessages: Paginate | undefined;
  currentSession: SessionMessage | undefined;
  currentSessionClosed: SessionMessage | undefined;
  currentSessionTransfer: SessionMessage | undefined;
  showNewChat: boolean;
  updateShowNewChat(): void;
  updateCurrentSession(session: SessionMessage | undefined): void;
  updateCurrentSessionClosed(session: SessionMessage | undefined): void;
  updateCurrentSessionTransfer(session: SessionMessage | undefined): void;
  updateSessionMessage(sessionMessage: Paginate): void;
  updateMessages(msg: Messages[]): void;
  updateNewMessage(newMessage: Paginate): void;
}

const ChatContext = createContext<ChatContextData>({} as ChatContextData);

const ChatProvider: React.FC = ({ children }) => {
  const [sessionMessages, setSessionMessages] = useState<Paginate>();
  const [messages, setMessages] = useState<Messages[]>([]);
  const [newMessages, setNewMessages] = useState<Paginate>();
  const [showNewChat, setShowNewChat] = useState(false);
  const [currentSession, setCurrentSession] = useState<
    SessionMessage | undefined
  >({} as SessionMessage);
  const [currentSessionClosed, setCurrentSessionClosed] = useState<
    SessionMessage | undefined
  >({} as SessionMessage);
  const [currentSessionTransfer, setCurrentSessionTransfer] = useState<
    SessionMessage | undefined
  >({} as SessionMessage);

  const updateCurrentSession = useCallback(
    async (session: SessionMessage | undefined) => {
      setCurrentSession(session);
    },
    [],
  );

  const updateCurrentSessionClosed = useCallback(
    async (session: SessionMessage | undefined) => {
      setCurrentSessionClosed(session);
    },
    [],
  );

  const updateCurrentSessionTransfer = useCallback(
    async (session: SessionMessage | undefined) => {
      setCurrentSessionTransfer(session);
    },
    [],
  );

  const updateMessages = useCallback(async (msg: Messages[]) => {
    if (msg) {
      setMessages(msg);
    }
  }, []);

  const updateSessionMessage = useCallback((sessionMessage: Paginate) => {
    setSessionMessages(sessionMessage);
  }, []);

  const updateNewMessage = useCallback((newMessage: Paginate) => {
    setNewMessages(newMessage);
  }, []);

  const updateShowNewChat = useCallback(() => {
    setShowNewChat(!showNewChat);
  }, [showNewChat]);

  return (
    <ChatContext.Provider
      value={{
        sessionMessages,
        messages,
        newMessages,
        currentSession,
        currentSessionClosed,
        currentSessionTransfer,
        showNewChat,
        updateShowNewChat,
        updateCurrentSession,
        updateCurrentSessionClosed,
        updateCurrentSessionTransfer,
        updateSessionMessage,
        updateMessages,
        updateNewMessage,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

function useChat(): ChatContextData {
  const context = useContext(ChatContext);

  if (!context) {
    throw new Error('useChat must be used within an ChatProvider');
  }

  return context;
}

export { ChatProvider, useChat };
