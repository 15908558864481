import React from 'react';
import { Helmet } from 'react-helmet';

import { useChat } from '../../hooks/chat';

import LeftSide from './LeftSide';
import RightSide from './RightSide';
import NewChat from './NewChat';

import { Container } from './styles';

const Chat: React.FC = () => {
  const { newMessages } = useChat();

  return (
    <Container>
      <Helmet>
        <title>
          {newMessages && newMessages?.data.length > 0
            ? `(${newMessages?.data.length}) Smart Message`
            : 'Smart Message'}
        </title>
      </Helmet>

      <NewChat />
      <LeftSide />
      <RightSide />
    </Container>
  );
};

export default Chat;
