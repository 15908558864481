import React, { ReactElement } from 'react';

import { MdDashboard, MdAssignment, MdChat } from 'react-icons/md';
import { GoSettings } from 'react-icons/go';

import { useSiedeBar } from '../../hooks/sidebar';
import { useAuth } from '../../hooks/auth';

import ItemMenu from './ItemMenu';

import { Container, MainMenu } from './styles';

interface Menu {
  name: string;
  route: string;
  icon?: ReactElement | null;
  children: Menu[];
}

// interface Permission {
//   id: string;
//   category: string;
//   name: string;
//   report_id: string;
// }

const SideBar: React.FC = () => {
  const { user } = useAuth();
  const { isOpened } = useSiedeBar();

  const menus: Menu[] = [];

  const userMenu = [
    {
      name: 'Chat',
      route: '/chat',
      icon: <MdChat size={24} color="#707070" />,
      children: [],
    },
  ];

  const adminMenu = [
    {
      name: 'Dashboard',
      route: '#',
      icon: <MdDashboard size={24} color="#707070" />,
      children: [
        {
          name: 'Home',
          route: '/dashboard',
          icon: null,
          children: [],
        },
        {
          name: 'Resumo Sessões',
          route: '/dashboard/session/resume',
          icon: null,
          children: [],
        },
      ],
    },
    {
      name: 'Whatsapp',
      route: '#',
      icon: <MdAssignment size={24} color="#707070" />,
      children: [
        {
          name: 'Conta',
          route: '/account',
          icon: null,
          children: [],
        },
        {
          name: 'Contatos',
          route: '/contacts',
          icon: null,
          children: [],
        },
        {
          name: 'Departamentos',
          route: '/departments',
          icon: null,
          children: [],
        },
        {
          name: 'Respostas',
          route: '/answers',
          icon: null,
          children: [],
        },
      ],
    },
    {
      name: 'Cadastros',
      route: '#',
      icon: <GoSettings size={24} color="#707070" />,
      children: [
        // {
        //   name: 'Permissões',
        //   route: '/permissions',
        //   icon: null,
        //   children: [],
        // },
        {
          name: 'Usuários',
          route: '/users',
          icon: null,
          children: [],
        },
      ],
    },
  ];

  if (user.role === 'admin') {
    adminMenu.map((adm) => menus.push(adm));
  }

  userMenu.map((usr) => menus.push(usr));

  return (
    <Container isMenuOpened={isOpened}>
      <MainMenu>
        {menus.map((menu: Menu) => (
          <ItemMenu key={menu.name} menu={menu} />
        ))}
      </MainMenu>
    </Container>
  );
};

export default SideBar;
