import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { Item, LabelMenu, SubMenu, IconArrowDown } from './styles';
import ItemSubMenu from '../ItemSubMenu';

interface Menu {
  name: string;
  route: string;
  icon?: ReactElement | null;
  children: Menu[];
}

interface Props {
  menu: Menu;
}

const ItemMenu: React.FC<Props> = ({ menu }) => {
  // const [active, setActive] = useState(false);

  // function toggleMenu(): void {
  //   console.log('active', active);
  //   setActive(!active);
  // }

  return (
    <>
      <Item key={menu.name}>
        <NavLink
          to={menu.route}
          activeClassName={menu.children.length > 0 ? '' : 'sideBarLinkActive'}
        >
          {menu.icon}
          <LabelMenu>{menu.name}</LabelMenu>
          {menu.children.length > 0 && <IconArrowDown />}
        </NavLink>
        {menu.children.length > 0 && (
          <SubMenu className="submenu">
            {menu.children.map((child: Menu) => (
              <ItemSubMenu key={child.name} menu={child} />
            ))}
          </SubMenu>
        )}
      </Item>
    </>
  );
};

export default ItemMenu;
