import React, { useCallback, useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaTags } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';
import {
  HeaderBackground,
  HeaderInfo,
  TextArea,
  Button,
  Label,
  FormGroup,
  Select,
} from '../../../components';

import { useToast } from '../../../hooks/toast';

import api from '../../../services/api';

import * as S from './styles';

interface FormData {
  type: number;
  message: string;
}

const schema = Yup.object().shape({
  type: Yup.number().required('Tipo obrigatório'),
  message: Yup.string().required('Mensagem obrigatória'),
});

interface Answer {
  id: string;
  type: number;
  message: string;
}

interface RouterParams {
  id?: string | undefined;
}

const AutoAnswersForm: React.FC = () => {
  const { id } = useParams<RouterParams>();

  const { register, handleSubmit, errors, reset } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState<Answer>();
  const history = useHistory();

  const onSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        if (answer?.id) {
          const response = await api.put<Answer>(
            `/auto/answers/${answer?.id}`,
            data,
          );

          setAnswer(response.data);
        } else {
          await api.post('/auto/answers', data);
          reset();

          history.push('/answers');
        }

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Salvo com sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },
    [answer?.id, addToast, reset, history],
  );

  useEffect(() => {
    if (id) {
      api.get<Answer>(`/auto/answers/${id}`).then((response) => {
        const { data } = response;
        reset(data);
        setAnswer(data);
      });
    }
  }, [id, reset]);

  return (
    <S.Container>
      <HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/answers">
              <MdKeyboardBackspace size={24} color="#fff" />
              Respostas
            </Link>
          </S.BackLink>
          <HeaderInfo
            title={
              answer?.id ? `Editando: ${answer?.message}` : 'Nova Mensagem'
            }
            icon={FaTags}
          />
        </S.HeaderPageDatail>
      </HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>Tipo</Label>
            <Select
              register={register}
              name="type"
              options={[{ id: '1', name: 'Saudação Inicial' }]}
              errors={errors.type}
            />
          </FormGroup>

          <FormGroup>
            <Label>Mensagem</Label>
            <TextArea
              register={register}
              errors={errors.message}
              name="message"
              placeholder="ex: Seja bem vindo ao atendimento da *A1 Tecnologia* , digite umas das seguintes opções abaixo: "
              cols={80}
              rows={15}
            />
          </FormGroup>

          <Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};

export default AutoAnswersForm;
