import React, { useState, useEffect, useCallback, useMemo } from 'react';
// eslint-disable-next-line import/no-duplicates
import { parseISO, formatRelative } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ptBR from 'date-fns/locale/pt-BR';

import api from '../../../services/api';

import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { useChat } from '../../../hooks/chat';
import { useSocket } from '../../../hooks/socket';

import ChatItem from './ChatItem';
import { Loader } from '../../../components';

import avartarImgDefault from '../../../assets/avatar.png';
import alert from '../../../assets/alert.mp3';

import * as S from './styles';

interface SessionMessage {
  id: string;
  phone: string;
  contact_name: string;
  last_message: string;
  current?: {
    name: string;
  };
  closed?: {
    name: string;
  };
  department?: {
    name: string;
  };
  transfer?: {
    name: string;
  };
  rating?: string;
  updated_at: string;
}

interface Paginate {
  data: SessionMessage[];
  page: number;
  per_page: number;
  last_page: number;
  total: number;
}
const LeftSide: React.FC = () => {
  const { user } = useAuth();
  const { socket } = useSocket();

  const {
    sessionMessages,
    currentSession,
    updateSessionMessage,
    updateCurrentSession,
    updateCurrentSessionClosed,
    updateCurrentSessionTransfer,
    updateShowNewChat,
    newMessages,
  } = useChat();

  const audio = useMemo(() => {
    return new Audio(alert);
  }, []);

  const updateSession = useCallback(async () => {
    const sessionResponse = await api.get<Paginate>('/message/session/user', {
      params: { page: 1, perPage: 20 },
    });

    updateSessionMessage(sessionResponse.data);
  }, [updateSessionMessage]);

  const listenSocket = useCallback((): void => {
    socket?.on('NEW_MESSAGE', () => {
      updateSession();

      audio.play();
    });
  }, [audio, socket, updateSession]);

  useEffect(() => {
    return () => {
      socket?.off('NEW_MESSAGE');
    };
  }, [socket]);

  useEffect(() => {
    listenSocket();
  }, [listenSocket]);

  useEffect(() => {
    return () => {
      updateCurrentSession(undefined);
    };
  }, [updateCurrentSession]);

  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const avatarImg = user.avatar_url ? user.avatar_url : avartarImgDefault;

  const handleSearch = useCallback(
    async ({ page, per_page }) => {
      try {
        const params = {
          page,
          per_page,
        };

        const response = await api.get<Paginate>('/message/session/user', {
          params,
        });

        const { data } = response;

        updateSessionMessage(data);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ops...',
          description: 'Ops, ocorreu um erro no processo de pesquisa',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, updateSessionMessage],
  );

  const handleSetSession = useCallback(
    (msg) => {
      updateCurrentSession(msg);
      updateCurrentSessionClosed({} as SessionMessage);
      updateCurrentSessionTransfer({} as SessionMessage);
    },
    [
      updateCurrentSession,
      updateCurrentSessionClosed,
      updateCurrentSessionTransfer,
    ],
  );

  useEffect(() => {
    const params = {
      page: 1,
      per_page: 10,
    };

    handleSearch(params);
  }, [handleSearch]);

  return (
    <S.Container>
      <S.Header>
        <img src={avatarImg} alt="profile" />
        <div>{user.department?.name}</div>
        <S.IconChat onClick={updateShowNewChat} />
        {newMessages?.data.length === 0 ? (
          <></>
        ) : (
          <span>{newMessages?.data.length.toString()}</span>
        )}
      </S.Header>

      {loading ? (
        <Loader loading={loading} align="center" message="Carregando..." />
      ) : (
        <S.ChatList>
          {sessionMessages?.data.map((msg) => (
            <S.SessionButton key={msg.id} onClick={() => handleSetSession(msg)}>
              <ChatItem
                key={msg.id}
                name={msg.contact_name}
                time={formatRelative(parseISO(msg.updated_at), new Date(), {
                  locale: ptBR,
                })}
                message={msg.last_message}
                isFocused={currentSession?.id === msg.id}
                transfer={msg.transfer?.name}
              />
            </S.SessionButton>
          ))}
        </S.ChatList>
      )}
    </S.Container>
  );
};

export default LeftSide;
