import React from 'react';
import { MdRefresh } from 'react-icons/md';

import * as S from './styles';
import avartarImgDefault from '../../../../assets/avatar.png';

interface ChatItemProps {
  name: string;
  time: string;
  message: string;
  isFocused: boolean;
  transfer?: string;
}

const ChatItem: React.FC<ChatItemProps> = ({
  name,
  time,
  message,
  isFocused,
  transfer,
}) => {
  return (
    <S.Container isFocused={isFocused}>
      <img src={avartarImgDefault} alt="Icon" />

      <S.Chats>
        <S.ChatLine>
          <S.Name>{name}</S.Name>
          <S.Date>{time}</S.Date>
        </S.ChatLine>

        <S.LastMessage>
          <p>{message}</p>
        </S.LastMessage>

        {transfer && (
          <S.SubTitle>
            <MdRefresh />
            {transfer}
          </S.SubTitle>
        )}
      </S.Chats>
    </S.Container>
  );
};

export default ChatItem;
