import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FiLogIn, FiMail } from 'react-icons/fi';
import * as Yup from 'yup';

import { useToast } from '../../hooks/toast';
import { Input, Button } from '../../components';
import logoImg from '../../assets/logo.png';
import { Container, Content, Background, AnimationContainer } from './styles';
import api from '../../services/api';

interface ForgotPasswordFormData {
  email: string;
  password: string;
}

const schema = Yup.object().shape({
  email: Yup.string()
    .required('Email obrigatório')
    .email('Digite um e-mail válido'),
});

const ForgotPassword: React.FC = () => {
  const { register, handleSubmit, errors } = useForm<ForgotPasswordFormData>({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const onSubmit = useCallback(
    async (data: ForgotPasswordFormData) => {
      setLoading(true);
      try {
        await api.post('/password/forgot', {
          email: data.email,
        });
        addToast({
          type: 'success',
          title: 'E-mail de recuperação enviando',
          description:
            'Enviamos um e-mail para confirmar a recuperação de senha, cheque sua caixa de entrada',
        });
        setLoading(false);
        // recuperação de senha
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro na recuperação de senha',
          description:
            'Ocorreu um erro ao tentar realizar a recuperação de senha, tente novamente',
        });
        setLoading(false);
      }
    },
    [addToast],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="Smart Message" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <h1>Recuperar senha</h1>
            <Input
              type="text"
              register={register}
              errors={errors.email}
              name="email"
              icon={FiMail}
              placeholder="E-mail"
            />
            <Button loading={loading} type="submit">
              Recuperar
            </Button>
          </form>

          <Link to="/signin">
            <FiLogIn />
            Voltar ao login
          </Link>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};

export default ForgotPassword;
