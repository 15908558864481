import styled, { css } from 'styled-components';

interface ButtonProps {
  isFocused: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const ContainerHeader = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  border-bottom: 1px solid var(--chat-border-color);
  justify-content: space-between;
  align-items: center;
  background: var(--primary);
`;

export const HeadInfo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  > img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  > div {
    font-size: 17px;
    color: var(--text-header);
  }
`;

export const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  padding: 20px 30px;
  background: var(--background-panel);

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border: 4px solid rgba(0, 0, 0, 0.2);
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--link-active-sidebar);
    border: 4px solid var(--link-active-sidebar);
  }
`;

export const DepartmentsContainer = styled.div``;

export const UsersContainer = styled.div`
  margin-top: 16px;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
  padding: 8px;
`;

export const ButtonContainer = styled.button<ButtonProps>`
  display: flex;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  background: transparent;
  border: 1px solid var(--border);
  align-items: center;
  cursor: pointer;
  height: 70px;
  overflow: hidden;
  color: inherit;

  ${(props) =>
    props.isFocused &&
    css`
      background: var(--chat-item-hover);
    `}

  > img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-left: 15px;
  }
`;

export const UserOnline = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* border-bottom: 1px solid var(--border); */
  padding-right: 15px;
  margin-left: 15px;

  flex-wrap: wrap;
  min-width: 0;
`;

export const UserLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Name = styled.div`
  color: var(--primary);
  font-size: 17px;
  font-weight: 500;
`;

export const Department = styled.div`
  width: 100%;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;

  > p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
    color: var(--chat-item-text-subtitle);
  }
`;

export const Online = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background: var(--chat-badge);
`;

export const SubimitContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px;

  button {
    margin: 0 4px;
  }
`;
