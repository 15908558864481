import React, { useCallback } from 'react';

import ChatIntro from './ChatIntro';
import ChatHeader from './ChatHeader';
import ChatBody from './ChatBody';
import ChatFooter from './ChatFooter';
import SessionClosed from './SessionClosed';
import SessionTransfer from './SessionTransfer';

import { useChat } from '../../../hooks/chat';

import { Container, ContainerIntro } from './styles';

const RightSide: React.FC = () => {
  const {
    currentSession,
    currentSessionClosed,
    currentSessionTransfer,
  } = useChat();

  const renderBody = useCallback(() => {
    if (currentSessionClosed?.id) {
      return <SessionClosed />;
    }

    if (currentSessionTransfer?.id) {
      return <SessionTransfer />;
    }

    return currentSession?.id ? (
      <Container>
        <ChatHeader />
        <ChatBody />
        <ChatFooter />
      </Container>
    ) : (
      <ContainerIntro>
        <ChatIntro />
      </ContainerIntro>
    );
  }, [
    currentSession?.id,
    currentSessionClosed?.id,
    currentSessionTransfer?.id,
  ]);

  return <>{renderBody()}</>;
};

export default RightSide;
