import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export const ContainerHeader = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  border-bottom: 1px solid var(--chat-border-color);
  justify-content: space-between;
  align-items: center;
  background: var(--primary);
`;

export const HeadInfo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  > img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  > div {
    font-size: 17px;
    color: var(--text-header);
  }
`;

export const ContainerBody = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  overflow-y: auto;
  padding: 20px 30px;
  background: var(--background-panel);

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border: 4px solid rgba(0, 0, 0, 0.2);
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--link-active-sidebar);
    border: 4px solid var(--link-active-sidebar);
  }
`;

export const SubimitContainer = styled.div`
  display: flex;
  flex-direction: row;

  button {
    margin: 0 4px;
  }
`;
